"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigRegistryApi = void 0;
const ICCanisterUtils_1 = require("src/configeek_assets/src/ic/ICCanisterUtils");
const typescriptAddons_1 = require("src/configeek_assets/src/system/typescriptAddons");
const canisters_1 = require("src/configeek_assets/src/ic/canisters");
const DEFAULT_TOPOLOGY_ID = 0;
let currentSessionTopologyId = DEFAULT_TOPOLOGY_ID;
class ConfigRegistryApi {
    constructor(projectApiKey, getConfigRegistryActor) {
        this.destroyed = false;
        this.callConfigRegistryRecursively = async () => {
            // console.log("ConfigRegistryApi.callConfigRegistryRecursively: will call getResult with 3 retries");
            const configRegistryResponse = await this.getResult(3);
            // console.log("ConfigRegistryApi.callConfigRegistryRecursively: configRegistryResponse", configRegistryResponse);
            if ((0, ICCanisterUtils_1.isOk)(configRegistryResponse)) {
                return configRegistryResponse;
            }
            else if ((0, ICCanisterUtils_1.isErr)(configRegistryResponse)) {
                switch (configRegistryResponse.err) {
                    case "changeTopology": {
                        if (!this.destroyed) {
                            // const timeout = 1000
                            // console.log("sleep for", timeout, "ms");
                            // await delayPromise(timeout)
                            return this.callConfigRegistryRecursively();
                        }
                        else {
                            console.warn("ConfigRegistryApi: changeTopology: callConfigRegistryRecursively skipped - destroyed");
                        }
                        break;
                    }
                    default: {
                    }
                }
            }
            return (0, ICCanisterUtils_1.createErrFatal)();
        };
        this.getResult = async (retriesLeft) => {
            // currentSessionTopologyId = APIStorage.coordinator.getTopologyId()
            let canisterIds = []; //APIStorage.coordinator.getCanisterIds();
            if (canisterIds.length === 0) {
                canisterIds = Array.from(canisters_1.RegistryCanisters);
            }
            if (canisterIds.length === 0) {
                console.warn("no canisters");
                return (0, ICCanisterUtils_1.createErrFatal)();
            }
            // console.log("ConfigRegistryApi.getResult using", {canisterIds: canisterIds});
            const canisterId = ConfigRegistryApi.getCanisterId(canisterIds);
            if (canisterId) {
                return this.getConfigStoreApiRecursively(canisterId, retriesLeft);
            }
            return (0, ICCanisterUtils_1.createErrFatal)();
        };
        this.getConfigStoreApiRecursively = async (canisterId, retriesLeft) => {
            // console.log("ConfigRegistryApi.getClientRegistryRecursively using", {currentSessionTopologyId, canisterId});
            if (canisterId) {
                let result;
                try {
                    result = await this.getConfigStoreApi(canisterId);
                }
                catch (e) {
                    console.warn("ConfigRegistryApi.getClientRegistryRecursively actor.getConfigStoreApi", e);
                    return (0, ICCanisterUtils_1.createErrFatal)();
                }
                // console.log("ConfigRegistryApi.getConfigStoreApiRecursively actor.getConfigStoreApi", result);
                if (result) {
                    if ((0, typescriptAddons_1.hasOwnProperty)(result, "ok")) {
                        if (result.ok.length === 1) {
                            const configStoreApiResult = result.ok[0];
                            return (0, ICCanisterUtils_1.createOkResult)(configStoreApiResult);
                        }
                    }
                    else if ((0, typescriptAddons_1.hasOwnProperty)(result, "changeTopology")) {
                        const changeTopology = result.changeTopology;
                        const newTopologyId = changeTopology.topologyId;
                        const newPrincipals = changeTopology.principals;
                        const newCanisterIds = [];
                        for (let i = 0; i < newPrincipals.length; i++) {
                            newCanisterIds.push(newPrincipals[i].toText());
                        }
                        // console.log("ConfigRegistryApi.getConfigStoreApiRecursively new canisterIds from new topology", newCanisterIds);
                        if (newCanisterIds.length > 0) {
                            currentSessionTopologyId = newTopologyId;
                            // APIStorage.coordinator.setCanisterIds(newCanisterIds)
                            // APIStorage.coordinator.setTopologyId(newTopologyId)
                        }
                        return (0, ICCanisterUtils_1.createErrResult)("changeTopology");
                    }
                    else if ((0, typescriptAddons_1.hasOwnProperty)(result, "redirect")) {
                        if (retriesLeft > 0) {
                            const principalToRedirectTo = result.redirect;
                            // console.warn("ConfigRegistryApi: getConfigStoreApiRecursively - will retry with new principal", principalToRedirectTo.toText())
                            return this.getConfigStoreApiRecursively(principalToRedirectTo.toText(), retriesLeft - 1);
                        }
                        else {
                            // console.warn("ConfigRegistryApi: getConfigStoreApiRecursively skipped - no more retries")
                        }
                        return (0, ICCanisterUtils_1.createErrFatal)();
                    }
                }
                //"result is undefined" OR "temporaryUnavailable" + "retryWithConsensusRequest" cases
                return (0, ICCanisterUtils_1.createErrFatal)();
            }
            return (0, ICCanisterUtils_1.createErrFatal)();
        };
        this.getConfigStoreApi = async (canisterId) => {
            const actor = await this.getConfigRegistryActor(canisterId);
            if (actor) {
                const topologyId = currentSessionTopologyId;
                return await actor.getConfigStoreApi(topologyId ? [topologyId] : [], this.projectApiKey);
            }
        };
        this.projectApiKey = projectApiKey;
        this.getConfigRegistryActor = getConfigRegistryActor;
    }
}
exports.ConfigRegistryApi = ConfigRegistryApi;
ConfigRegistryApi.getRandomCanisterId = (array) => {
    const index = Math.floor(Math.random() * array.length);
    return array[index];
};
ConfigRegistryApi.getCanisterId = (canisterIds) => {
    if (canisterIds.length == 0) {
        return undefined;
    }
    return ConfigRegistryApi.getRandomCanisterId(canisterIds);
};
