"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppLoggedIn = void 0;
const React = __importStar(require("react"));
const react_1 = require("react");
const SkeletonComponent_1 = require("src/configeek_assets/src/components/common/SkeletonComponent");
const ToolbarComponent_1 = require("src/configeek_assets/src/components/loggedIn/toolbar/ToolbarComponent");
const RootContentComponent_1 = require("src/configeek_assets/src/components/loggedIn/content/RootContentComponent");
const ActorsProvider_1 = require("src/configeek_assets/src/components/common/provider/ActorsProvider");
const ProjectsDataProvider_1 = require("src/configeek_assets/src/components/common/provider/ProjectsDataProvider");
const PageRedirectComponent_1 = require("src/configeek_assets/src/components/common/PageRedirectComponent");
const usergeek_ic_js_1 = require("usergeek-ic-js");
const constants_1 = require("src/configeek_assets/src/constants");
const PageLoaderComponent_1 = require("src/configeek_assets/src/components/common/widget/PageLoaderComponent");
const geekfactory_ic_js_auth_1 = require("geekfactory-ic-js-auth");
const USERGEEK_PROJECT_API_KEY = "0152010888DC73EE8595DDE3EBFD2999"; // "Configeek Management" on production
const USERGEEK_PROJECT_API_KEY_DEV = "011A010B1A82BC23BCEA505E6E90FF42"; // "Configeek Management DEV" on production
const AppLoggedIn = () => {
    const { state: { identity } } = (0, geekfactory_ic_js_auth_1.useAuthProviderContext)();
    const [usergeekInitialized, setUsergeekInitialized] = (0, react_1.useState)(false);
    React.useEffect(() => {
        try {
            if (identity) {
                if (process.env.NODE_ENV === "development") {
                    usergeek_ic_js_1.Usergeek.init({ apiKey: USERGEEK_PROJECT_API_KEY_DEV, host: constants_1.URL_IC_ROOT_DOMAIN });
                }
                else {
                    usergeek_ic_js_1.Usergeek.init({ apiKey: USERGEEK_PROJECT_API_KEY, host: constants_1.URL_IC_ROOT_DOMAIN });
                }
                usergeek_ic_js_1.Usergeek.setPrincipal(identity.getPrincipal());
                usergeek_ic_js_1.Usergeek.trackSession();
            }
        }
        catch (e) {
            console.error(`Usergeek: caught initialization error`, e);
        }
        finally {
            setUsergeekInitialized(true);
        }
    }, [identity]);
    if (!usergeekInitialized) {
        return React.createElement(PageLoaderComponent_1.PageLoaderComponent, null);
    }
    return React.createElement(ActorsProvider_1.ActorsProvider, null,
        React.createElement(ProjectsDataProvider_1.ProjectsDataProvider, null,
            React.createElement(PageRedirectComponent_1.PageRedirectComponent, null,
                React.createElement(SkeletonComponent_1.SkeletonComponent, { toolbarComponent: ToolbarComponent_1.ToolbarComponent, contentComponent: RootContentComponent_1.RootContentComponent }))));
};
exports.AppLoggedIn = AppLoggedIn;
