"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMediaQuery = void 0;
const react_1 = require("react");
function useMediaQuery(query) {
    const [matches, setMatches] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const changeListener = function (e) {
            if (e.matches !== matches) {
                setMatches(media.matches);
            }
        };
        media.addEventListener("change", changeListener);
        const listener = () => setMatches(media.matches);
        window.addEventListener("resize", listener);
        return () => {
            media.removeEventListener("change", changeListener);
            window.removeEventListener("resize", listener);
        };
    }, [matches, query]);
    return matches;
}
exports.useMediaQuery = useMediaQuery;
