"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrustedBySection = void 0;
const React = __importStar(require("react"));
const antd_1 = require("antd");
const DSCVRIcon_1 = require("./DSCVRIcon");
const DSocialIcon_1 = require("./DSocialIcon");
const OpenChatIcon_1 = require("./OpenChatIcon");
const NuanceIcon_1 = require("./NuanceIcon");
const BTCFlowerIcon_1 = require("./BTCFlowerIcon");
const ICPSquadIcon_1 = require("./ICPSquadIcon");
const ModclubIcon_1 = require("./ModclubIcon");
const CosmicraftsIcon_1 = require("./CosmicraftsIcon");
const SagaLegendsIcon_1 = require("./SagaLegendsIcon");
const NFIDIcon_1 = require("./NFIDIcon");
const KontributeIcon_1 = require("./KontributeIcon");
const PawsArenaIcon_1 = require("./PawsArenaIcon");
const PAWS_ARENA_URL = "https://knbkj-fiaaa-aaaan-qaadq-cai.ic0.app";
const TrustedBySection = () => {
    return React.createElement("section", { className: "ug_anonym_section" },
        React.createElement("div", { className: "ug_anonym_sectionContent" },
            React.createElement(antd_1.Row, { justify: "center" },
                React.createElement(antd_1.Col, { span: 22 },
                    React.createElement(antd_1.Row, { justify: "center", className: "pt-5 pb-lg-5" },
                        React.createElement(antd_1.Col, { span: 20 },
                            React.createElement("div", { className: "card-info" },
                                React.createElement("div", { className: "title2" }, "Our Partners")))),
                    React.createElement(antd_1.Row, { justify: "center", className: "pb-5", gutter: [8, 16] },
                        React.createElement(antd_1.Col, { span: 8 },
                            React.createElement("a", { href: "https://dscvr.one", target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement(DSCVRIcon_1.DSCVRIcon, null),
                                    React.createElement("div", { className: "title3" }, "DSCVR")))),
                        React.createElement(antd_1.Col, { span: 8 },
                            React.createElement("a", { href: "https://dsocial.app", target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement(DSocialIcon_1.DSocialIcon, null),
                                    React.createElement("div", { className: "title3" }, "DSocial")))),
                        React.createElement(antd_1.Col, { span: 8 },
                            React.createElement("a", { href: "https://oc.app", target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement(OpenChatIcon_1.OpenChatIcon, null),
                                    React.createElement("div", { className: "title3" }, "OpenChat")))),
                        React.createElement(antd_1.Col, { span: 8 },
                            React.createElement("a", { href: "http://www.nuance.xyz", target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement(NuanceIcon_1.NuanceIcon, null),
                                    React.createElement("div", { className: "title3" }, "Nuance")))),
                        React.createElement(antd_1.Col, { span: 8 },
                            React.createElement("a", { href: "https://btcflower.xyz", target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement(BTCFlowerIcon_1.BTCFlowerIcon, null),
                                    React.createElement("div", { className: "title3" }, "BTC Flower")))),
                        React.createElement(antd_1.Col, { span: 8 },
                            React.createElement("a", { href: "https://icpsquad.dfinitycommunity.com/", target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement(ICPSquadIcon_1.ICPSquadIcon, null),
                                    React.createElement("div", { className: "title3" }, "ICP Squad")))),
                        React.createElement(antd_1.Col, { span: 8 },
                            React.createElement("a", { href: "https://ljyte-qiaaa-aaaah-qaiva-cai.raw.ic0.app", target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement(ModclubIcon_1.ModclubIcon, null),
                                    React.createElement("div", { className: "title3" }, "Modclub")))),
                        React.createElement(antd_1.Col, { span: 8 },
                            React.createElement("a", { href: "https://cosmicrafts.com", target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement(CosmicraftsIcon_1.CosmicraftsIcon, null),
                                    React.createElement("div", { className: "title3" }, "Cosmicrafts")))),
                        React.createElement(antd_1.Col, { span: 8 },
                            React.createElement("a", { href: "https://legends.saga.cards", target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement(SagaLegendsIcon_1.SagaLegendsIcon, null),
                                    React.createElement("div", { className: "title3" }, "Saga Legends")))),
                        React.createElement(antd_1.Col, { span: 8 },
                            React.createElement("a", { href: "https://nfid.one/", target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement(NFIDIcon_1.NFIDIcon, null),
                                    React.createElement("div", { className: "title3" }, "NFID")))),
                        React.createElement(antd_1.Col, { span: 8 },
                            React.createElement("a", { href: "https://kontribute.app", target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement(KontributeIcon_1.KontributeIcon, null),
                                    React.createElement("div", { className: "title3" }, "Kontribute")))),
                        React.createElement(antd_1.Col, { span: 8 },
                            React.createElement("a", { href: PAWS_ARENA_URL, target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement(PawsArenaIcon_1.PawsArenaIcon, null),
                                    React.createElement("div", { className: "title3" }, "Paws Arena")))))))));
};
exports.TrustedBySection = TrustedBySection;
