"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectWithDataComponent = void 0;
const React = __importStar(require("react"));
const ProjectParametersPage_1 = require("src/configeek_assets/src/components/loggedIn/content/project/data/parameters/ProjectParametersPage");
const react_router_dom_1 = require("react-router-dom");
const PrivateRoute_1 = require("src/configeek_assets/src/components/common/widget/PrivateRoute");
const AppRouter_1 = require("src/configeek_assets/src/components/common/AppRouter");
const ProjectUrlProvider_1 = require("src/configeek_assets/src/components/common/provider/ProjectUrlProvider");
const ProjectAuditLogPage_1 = require("src/configeek_assets/src/components/loggedIn/content/project/data/audit/ProjectAuditLogPage");
const AuditLogPageModelProvider_1 = require("src/configeek_assets/src/components/loggedIn/content/project/data/audit/model/AuditLogPageModelProvider");
const AuditLogPageDataProvider_1 = require("src/configeek_assets/src/components/loggedIn/content/project/data/audit/model/AuditLogPageDataProvider");
const AuditLogTableDataProvider_1 = require("src/configeek_assets/src/components/loggedIn/content/project/data/audit/AuditLogTableDataProvider");
const ProjectAuditPathComponent = () => {
    const { projectApiKey } = (0, ProjectUrlProvider_1.useProjectUrlContext)();
    return React.createElement(React.Fragment, null,
        React.createElement(AuditLogPageModelProvider_1.AuditLogPageModelProvider, { projectApiKey: projectApiKey },
            React.createElement(AuditLogPageDataProvider_1.AuditLogPageDataProvider, { projectApiKey: projectApiKey },
                React.createElement(AuditLogTableDataProvider_1.AuditLogTableDataProvider, null,
                    React.createElement(ProjectAuditLogPage_1.ProjectAuditLogPage, null)))));
};
const ProjectWithDataComponent = () => {
    const { projectApiKey } = (0, ProjectUrlProvider_1.useProjectUrlContext)();
    return React.createElement(React.Fragment, null,
        React.createElement(react_router_dom_1.Switch, null,
            React.createElement(PrivateRoute_1.PrivateRoute, { path: AppRouter_1.PATH__PROJECT_PARAMETERS, exact: true, component: ProjectParametersPage_1.ProjectParametersPage }),
            React.createElement(PrivateRoute_1.PrivateRoute, { path: AppRouter_1.PATH__PROJECT_AUDIT, exact: true, component: ProjectAuditPathComponent }),
            React.createElement(react_router_dom_1.Redirect, { from: "*", to: AppRouter_1.PathGenerator.project(projectApiKey) })));
};
exports.ProjectWithDataComponent = ProjectWithDataComponent;
