"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivateRoute = void 0;
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const AppRouter_1 = require("src/configeek_assets/src/components/common/AppRouter");
const geekfactory_ic_js_auth_1 = require("geekfactory-ic-js-auth");
const PrivateRoute = (props) => {
    const location = (0, react_router_dom_1.useLocation)();
    const authProviderState = (0, geekfactory_ic_js_auth_1.useAuthProviderContext)();
    const { component: Component, remounting, exactRouteMatchPath, ...rest } = props;
    const exactRouteMatchPathRouteMatchResult = (0, react_router_dom_1.useRouteMatch)(exactRouteMatchPath || {});
    let componentKey = null;
    if (remounting === true) {
        if (exactRouteMatchPath) {
            if (exactRouteMatchPathRouteMatchResult) {
                componentKey = exactRouteMatchPathRouteMatchResult.url;
            }
        }
        if (!componentKey) {
            componentKey = location.pathname;
        }
    }
    return (React.createElement(react_router_dom_1.Route, { ...rest, render: props => {
            return !authProviderState.status.isLoggedIn ?
                React.createElement(react_router_dom_1.Redirect, { to: {
                        pathname: AppRouter_1.PATH__HOME,
                        state: { from: props.location }
                    } })
                :
                    React.createElement(Component, { ...rest, ...props, key: componentKey });
        } }));
};
exports.PrivateRoute = PrivateRoute;
