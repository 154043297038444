"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowNewLinesSettingsComponent = void 0;
const React = __importStar(require("react"));
const RadioSettingsComponent_1 = require("src/configeek_assets/src/components/common/widget/RadioSettingsComponent");
const newLinesVisibleOptions = [
    { value: false, label: "No" },
    { value: true, label: "Yes" },
];
const ShowNewLinesSettingsComponent = (props) => {
    return React.createElement(RadioSettingsComponent_1.RadioSettingsComponent, { value: props.value, options: newLinesVisibleOptions, disabled: props.disabled, onChange: props.onChange, bottomPadding: props.bottomPadding == undefined ? true : props.bottomPadding, label: props.label || "Show New Lines" });
};
exports.ShowNewLinesSettingsComponent = ShowNewLinesSettingsComponent;
