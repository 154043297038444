"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CanistergeekIconSVG = void 0;
const React = __importStar(require("react"));
const CanistergeekIconSVG = () => React.createElement("svg", { width: "1em", height: "1em", version: "1.1", viewBox: "0 0 1230 400", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { transform: "matrix(4.6043 0 0 4.6043 -535.79 -1117.1)", fill: "#333d29" },
        React.createElement("rect", { x: "188.2", y: "183.2", width: "126", height: "17.5" }),
        React.createElement("rect", { x: "206.9", y: "154.2", width: "88.6", height: "17.5" }),
        React.createElement("path", { d: "m323.2 216.2v15.5h-52.7v28.3h-38.6v-28.3h-52.9v-15.5h-16.7v164.5c0 20.6 39.8 37.3 88.9 37.3s86.6-15.8 88.8-35.6l0.1-81.1v-85zm-35.2 33.2h36v36h-36zm-108-0.5h36v36.9h-36zm143.6 128.5c0 0.3 0 0.7-0.1 1-0.1 0.2-0.1 0.5-0.2 0.7s-0.1 0.4-0.2 0.6c-5.1 12.5-35.3 22.1-71.9 22.1s-66.7-9.6-71.8-22.1c-0.1-0.2-0.1-0.4-0.2-0.5v-77.9h52.7v-24.8h38.5v49.1h-46.4v16.5h63v-40.9h36.7v76.2z" })));
exports.CanistergeekIconSVG = CanistergeekIconSVG;
