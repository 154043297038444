"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userSettingsStorage = exports.UserSettingsStorage = void 0;
const Theme_1 = require("src/configeek_assets/src/system/theme/Theme");
const KeyValueStoreFacade_1 = require("src/configeek_assets/src/system/store/KeyValueStoreFacade");
const store = KeyValueStoreFacade_1.KeyValueStoreFacade.createStore("configeek-theme-store-v1--");
class UserSettingsStorage {
    constructor() {
        this.saveDictionary = () => store.set("dictionary", this.dictionary);
        this.dictionary = store.get("dictionary") || {};
    }
    get theme() {
        return this.dictionary.theme || "light";
    }
    set theme(value) {
        this.dictionary.theme = (0, Theme_1.validateThemeId)(value);
        this.saveDictionary();
    }
}
exports.UserSettingsStorage = UserSettingsStorage;
const userSettingsStorage = new UserSettingsStorage();
exports.userSettingsStorage = userSettingsStorage;
