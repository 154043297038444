"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioSettingsComponent = void 0;
const React = __importStar(require("react"));
const react_1 = require("react");
const antd_1 = require("antd");
function RadioSettingsComponent(props) {
    const onChangeChartType = (0, react_1.useCallback)((e) => {
        const value = e.target.value;
        props.onChange(value);
    }, [props.onChange]);
    const rowStyle = {
        ...props.rowStyle,
    };
    if (props.bottomPadding === true) {
        rowStyle.marginBottom = 10;
    }
    return React.createElement(antd_1.Row, { justify: "end", align: "middle", className: "radioSettingsRow", style: rowStyle },
        React.createElement(antd_1.Col, null,
            React.createElement(antd_1.Space, { className: "radioSettingsComponent" },
                props.label,
                React.createElement(antd_1.Radio.Group, { value: props.value, size: props.size || "small", disabled: props.disabled, optionType: "button", buttonStyle: "solid", onChange: onChangeChartType }, props.options.map(option => {
                    return React.createElement(antd_1.Radio.Button, { value: option.value, key: `${option.value}`, disabled: option.disabled }, option.label);
                })))));
}
exports.RadioSettingsComponent = RadioSettingsComponent;
