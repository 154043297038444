"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegistryCanisters = exports.allKnownCanisterIds = exports.Canisters = void 0;
exports.Canisters = {
    frontend: `${process.env.FRONTEND_CANISTER}`,
    management: `${process.env.MANAGEMENT_CANISTER_ID}`,
    configStore0: `${process.env.CONFIGSTORE0_CANISTER_ID}`,
    registry0: `${process.env.REGISTRY0_CANISTER_ID}`,
    registry128: `${process.env.REGISTRY128_CANISTER_ID}`,
};
exports.allKnownCanisterIds = [
    exports.Canisters.management,
    exports.Canisters.configStore0,
    exports.Canisters.registry0,
    exports.Canisters.registry128,
];
if (!!process.env.IS_TEST_SERVER) {
    console.log("allKnownCanisterIds", exports.allKnownCanisterIds);
    console.log("DFX_NETWORK", process.env.DFX_NETWORK);
}
exports.RegistryCanisters = [
    exports.Canisters.registry0,
    exports.Canisters.registry128,
];
