"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.i18 = void 0;
const productName = "confiGeek";
exports.i18 = {
    common: {
        productName: productName,
        error: {
            tryAgain: "Try Again",
            oops: {
                title: "Oops, something went wrong"
            }
        }
    },
    projects: {
        error: {
            modal: {
                create: "Project cannot be created. Please try again.",
                rename: "Project cannot be renamed. Please try again.",
            }
        }
    },
    project: {
        configuration: {
            common: {
                header: {
                    createButton: "Add new key"
                }
            },
            noKeysStub: {
                title: "No configuration keys for this project yet.",
                createButton: "Add new key",
            },
            error: {
                modal: {
                    create: "Configuration parameter cannot be created. Please try again.",
                    edit: "Configuration parameter cannot be changed. Please try again.",
                    remove: "Configuration parameter cannot be removed. Please try again.",
                }
            }
        },
        members: {
            common: {
                header: {
                    inviteButton: "Invite"
                }
            },
            error: {
                table: {
                    noAccess: {
                        msg: "Only project Owner can view and modify members.",
                        backToProjectsLink: "Back to projects",
                    }
                },
                modal: {
                    invite: "Member cannot be invited. Please try again.",
                    edit: "Member cannot be updated. Please try again.",
                    remove: "Member cannot be removed. Please try again.",
                },
            }
        },
        audit: {
            error: {
                table: {
                    noAccess: {
                        msg: "Only project Owner can view audit logs.",
                        backToParametersLink: "Back to Configuration",
                    }
                }
            },
            noRecordsStub: {
                title: "No matching audit log records found.",
            },
            filterParametersChanged: "Audit Log parameters changed, please press SHOW button above"
        },
    }
};
