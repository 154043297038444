"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StakegeekIconSVG = void 0;
const React = __importStar(require("react"));
const StakegeekIconSVG = () => React.createElement("svg", { width: "1em", height: "1em", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { fill: "currentColor" },
        React.createElement("polygon", { points: "18.24 22.58 5.76 22.58 5.76 17.25 4.35 17.25 4.35 24 19.65 24 19.65 17.25 18.24 17.25 18.24 22.58" }),
        React.createElement("path", { d: "M16.31,10.06c-1.36,0-3.42,1.44-4.31,2.12-.9-.68-2.95-2.12-4.31-2.12-1.84,0-3.34,1.33-3.34,2.96s1.5,2.96,3.34,2.96c1.36,0,3.42-1.44,4.31-2.12.39.29.97.71,1.65,1.11v2.98h-4v1.42h5.42v-3.68c.44.17.87.3,1.25.3,1.84,0,3.34-1.33,3.34-2.96s-1.5-2.96-3.34-2.96ZM10.96,13.02c-1.07.78-2.52,1.66-3.27,1.66-1.13,0-2.05-.75-2.05-1.67s.92-1.67,2.05-1.67c.75,0,2.2.88,3.27,1.67h0s0,0,0,0ZM16.31,14.68c-.75,0-2.2-.88-3.27-1.67h0s0,0,0,0c1.07-.78,2.52-1.66,3.27-1.66,1.13,0,2.05.75,2.05,1.67s-.92,1.67-2.05,1.67Z" }),
        React.createElement("polygon", { points: "10.1 8.54 13.16 6.54 13.5 4.02 12.15 1.08 9.02 2.22 9.38 4.51 10.73 4.3 10.55 3.12 11.43 2.8 12.09 4.23 11.89 5.74 9.65 7.2 7.2 7.36 4.99 6.17 4.35 7.38 6.89 8.75 10.1 8.54" }),
        React.createElement("polygon", { points: "15.22 8.81 16.59 8.77 16.43 3.09 14.97 0 13.73 .59 15.07 3.41 15.22 8.81" }),
        React.createElement("rect", { x: "18.18", y: "3.12", width: "1.37", height: "5.66" })));
exports.StakegeekIconSVG = StakegeekIconSVG;
