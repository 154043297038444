"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToolbarRow = void 0;
const React = __importStar(require("react"));
const antd_1 = require("antd");
const icons_1 = require("@ant-design/icons");
const react_router_dom_1 = require("react-router-dom");
const AppRouter_1 = require("src/configeek_assets/src/components/common/AppRouter");
const constants_1 = require("src/configeek_assets/src/constants");
const DiscordIcon_1 = require("src/configeek_assets/src/components/anonymous/common/icons/DiscordIcon");
const LogoWithBrandIcon_1 = require("src/configeek_assets/src/components/anonymous/common/icons/LogoWithBrandIcon");
const LoginModal_1 = require("src/configeek_assets/src/components/anonymous/landing/LoginModal");
const ToolbarRow = (props) => {
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "ug_anonym_toolbarRow" },
            React.createElement("div", { className: "ug_anonym_sectionContent" },
                React.createElement(antd_1.Row, null,
                    React.createElement(antd_1.Col, { span: 2 }),
                    React.createElement(antd_1.Col, { span: 20 },
                        React.createElement(antd_1.Row, { align: "middle" },
                            React.createElement(antd_1.Col, { flex: "auto" }, props.logoAsLinkToHome ?
                                React.createElement(react_router_dom_1.Link, { to: AppRouter_1.PATH__HOME },
                                    React.createElement(LogoWithBrandIcon_1.LogoWithBrandIcon, null)) :
                                React.createElement(LogoWithBrandIcon_1.LogoWithBrandIcon, null)),
                            React.createElement(antd_1.Col, null,
                                React.createElement(antd_1.Row, { justify: "end" },
                                    React.createElement(antd_1.Col, { flex: "auto" },
                                        React.createElement(antd_1.Row, { align: "middle", gutter: 25 },
                                            React.createElement(antd_1.Col, { className: "noWrap" },
                                                React.createElement("a", { href: constants_1.URL_MAIL_SUPPORT },
                                                    React.createElement(antd_1.Button, { icon: React.createElement(icons_1.MailOutlined, { style: { fontSize: "23px" } }), type: "link" }))),
                                            React.createElement(antd_1.Col, { className: "noWrap" },
                                                React.createElement("a", { href: constants_1.URL_TWITTER, target: "_blank" },
                                                    React.createElement(antd_1.Button, { icon: React.createElement(icons_1.TwitterOutlined, { style: { fontSize: "23px" } }), type: "link" }))),
                                            React.createElement(antd_1.Col, { className: "noWrap" },
                                                React.createElement("a", { href: constants_1.URL_DISCORD, target: "_blank" },
                                                    React.createElement(antd_1.Button, { icon: React.createElement(DiscordIcon_1.DiscordIcon, { style: { fontSize: "23px" } }), type: "link" }))),
                                            React.createElement(antd_1.Col, { className: "noWrap" },
                                                React.createElement("a", { href: constants_1.URL_GITHUB, target: "_blank" },
                                                    React.createElement(antd_1.Button, { icon: React.createElement(icons_1.GithubOutlined, { style: { fontSize: "21px" } }), type: "link" }))),
                                            React.createElement(antd_1.Col, { className: "noWrap" },
                                                React.createElement(LoginModal_1.SignUpButton, null)))))))),
                    React.createElement(antd_1.Col, { span: 2 })))));
};
exports.ToolbarRow = ToolbarRow;
