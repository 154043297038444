"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsergeekIconSVG = void 0;
const React = __importStar(require("react"));
const UsergeekIconSVG = () => React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "currentColor", version: "1.1", viewBox: "0 0 1000 1363", height: "1em", width: "1em" },
    React.createElement("g", null,
        React.createElement("path", { fill: "currentColor", d: "M 406.691 890.808 L 406.691 1013.33 L 646.722 1013.33 L 646.722 1095.02 L 365.851 1095.02 L 325.005 1095.02 L 325.005 1054.18 L 325.005 890.808 L 97.8723 890.808 L 57.026 890.808 L 57.026 849.968 L 57.026 581.991 L 57.026 541.143 L 97.8723 541.143 L 365.851 541.143 L 406.691 541.143 L 406.691 581.991 L 406.691 675.142 L 605.883 675.142 L 605.883 581.991 L 605.883 541.143 L 646.722 541.143 L 914.701 541.143 L 955.548 541.143 L 955.548 581.991 L 955.548 849.968 L 955.548 890.808 L 914.701 890.808 L 646.722 890.808 L 605.883 890.808 L 605.883 849.968 L 605.883 756.822 L 406.691 756.822 L 406.691 786.195 L 406.691 849.968 L 406.691 890.807 Z M 481.464 214.419 L 852.842 0 L 893.681 70.7401 L 503.357 296.099 L 57.026 296.099 L 57.026 214.419 Z M 710.482 459.465 L 1016.19 282.959 L 1057.02 353.699 L 873.855 459.465 Z M 525.243 377.785 L 934.481 141.5 L 975.313 212.24 L 547.129 459.465 L 57.025 459.465 L 57.025 377.785 L 525.242 377.785 Z M 955.548 972.502 L 955.548 1322.16 L 955.548 1363 L 914.701 1363 L 97.8723 1363 L 57.026 1363 L 57.026 1322.16 L 57.026 972.502 L 138.705 972.502 L 138.705 1281.33 L 873.868 1281.33 L 873.868 972.502 Z M 325.005 622.836 L 138.705 622.836 L 138.705 809.129 L 325.005 809.129 L 325.005 786.195 Z M 873.868 622.836 L 687.569 622.836 L 687.569 809.129 L 873.868 809.129 Z" })));
exports.UsergeekIconSVG = UsergeekIconSVG;
