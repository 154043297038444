"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardRefreshComponent = void 0;
const React = __importStar(require("react"));
const react_1 = require("react");
const antd_1 = require("antd");
const icons_1 = require("@ant-design/icons");
const AdminMGTProvider_1 = require("src/configeek_assets/src/components/loggedIn/content/admin/AdminMGTProvider");
const DashboardRefreshComponent = () => {
    const adminMGTContext = (0, AdminMGTProvider_1.useAdminMGTContext)();
    const inProgress = adminMGTContext.mgtInfo.status.inProgress;
    const onClick = (0, react_1.useCallback)(() => {
        adminMGTContext.getInformation();
    }, [adminMGTContext.getInformation]);
    return React.createElement(antd_1.Button, { icon: React.createElement(icons_1.ReloadOutlined, null), onClick: onClick, disabled: inProgress, loading: inProgress });
};
exports.DashboardRefreshComponent = DashboardRefreshComponent;
