export const idlFactory = ({ IDL }) => {
  const AccessToken = IDL.Text;
  const TimeInSeconds = IDL.Nat32;
  const UpdateId = IDL.Nat32;
  const AuditRequest = IDL.Record({
    'keyFilter' : IDL.Opt(IDL.Text),
    'timeFilter' : IDL.Opt(TimeInSeconds),
    'count' : IDL.Nat8,
    'updateId' : IDL.Opt(UpdateId),
    'valueFilter' : IDL.Opt(IDL.Text),
    'orderDescending' : IDL.Bool,
  });
  const ConfigKey = IDL.Text;
  const AuditActionType = IDL.Variant({
    'new' : IDL.Null,
    'remove' : IDL.Null,
    'modify' : IDL.Null,
  });
  const ConfigValue = IDL.Variant({ 'none' : IDL.Null, 'text' : IDL.Text });
  const AuditRecord = IDL.Tuple(
    UpdateId,
    ConfigKey,
    AuditActionType,
    IDL.Opt(ConfigValue),
    IDL.Opt(ConfigValue),
    IDL.Principal,
    TimeInSeconds,
  );
  const AuditRequestResult = IDL.Record({
    'records' : IDL.Vec(AuditRecord),
    'haveMore' : IDL.Bool,
  });
  const ConfigApiError = IDL.Variant({ 'wrongToken' : IDL.Null });
  const GetAuditResult = IDL.Variant({
    'ok' : AuditRequestResult,
    'err' : ConfigApiError,
  });
  const GetLogMessagesFilter = IDL.Record({
    'analyzeCount' : IDL.Nat32,
    'messageRegex' : IDL.Opt(IDL.Text),
    'messageContains' : IDL.Opt(IDL.Text),
  });
  const Nanos = IDL.Nat64;
  const GetLogMessagesParameters = IDL.Record({
    'count' : IDL.Nat32,
    'filter' : IDL.Opt(GetLogMessagesFilter),
    'fromTimeNanos' : IDL.Opt(Nanos),
  });
  const GetLatestLogMessagesParameters = IDL.Record({
    'upToTimeNanos' : IDL.Opt(Nanos),
    'count' : IDL.Nat32,
    'filter' : IDL.Opt(GetLogMessagesFilter),
  });
  const CanisterLogRequest = IDL.Variant({
    'getMessagesInfo' : IDL.Null,
    'getMessages' : GetLogMessagesParameters,
    'getLatestMessages' : GetLatestLogMessagesParameters,
  });
  const CanisterLogFeature = IDL.Variant({
    'filterMessageByContains' : IDL.Null,
    'filterMessageByRegex' : IDL.Null,
  });
  const CanisterLogMessagesInfo = IDL.Record({
    'features' : IDL.Vec(IDL.Opt(CanisterLogFeature)),
    'lastTimeNanos' : IDL.Opt(Nanos),
    'count' : IDL.Nat32,
    'firstTimeNanos' : IDL.Opt(Nanos),
  });
  const LogMessagesData = IDL.Record({
    'timeNanos' : Nanos,
    'message' : IDL.Text,
  });
  const CanisterLogMessages = IDL.Record({
    'data' : IDL.Vec(LogMessagesData),
    'lastAnalyzedMessageTimeNanos' : IDL.Opt(Nanos),
  });
  const CanisterLogResponse = IDL.Variant({
    'messagesInfo' : CanisterLogMessagesInfo,
    'messages' : CanisterLogMessages,
  });
  const MetricsGranularity = IDL.Variant({
    'hourly' : IDL.Null,
    'daily' : IDL.Null,
  });
  const GetMetricsParameters__1 = IDL.Record({
    'dateToMillis' : IDL.Nat,
    'granularity' : MetricsGranularity,
    'dateFromMillis' : IDL.Nat,
  });
  const UpdateCallsAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterHeapMemoryAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterCyclesAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterMemoryAggregatedData = IDL.Vec(IDL.Nat64);
  const HourlyMetricsData = IDL.Record({
    'updateCalls' : UpdateCallsAggregatedData,
    'canisterHeapMemorySize' : CanisterHeapMemoryAggregatedData,
    'canisterCycles' : CanisterCyclesAggregatedData,
    'canisterMemorySize' : CanisterMemoryAggregatedData,
    'timeMillis' : IDL.Int,
  });
  const NumericEntity = IDL.Record({
    'avg' : IDL.Nat64,
    'max' : IDL.Nat64,
    'min' : IDL.Nat64,
    'first' : IDL.Nat64,
    'last' : IDL.Nat64,
  });
  const DailyMetricsData = IDL.Record({
    'updateCalls' : IDL.Nat64,
    'canisterHeapMemorySize' : NumericEntity,
    'canisterCycles' : NumericEntity,
    'canisterMemorySize' : NumericEntity,
    'timeMillis' : IDL.Int,
  });
  const CanisterMetricsData = IDL.Variant({
    'hourly' : IDL.Vec(HourlyMetricsData),
    'daily' : IDL.Vec(DailyMetricsData),
  });
  const CanisterMetrics__1 = IDL.Record({ 'data' : CanisterMetricsData });
  const StatusRequest = IDL.Record({
    'memory_size' : IDL.Bool,
    'cycles' : IDL.Bool,
    'heap_memory_size' : IDL.Bool,
  });
  const GetMetricsParameters = IDL.Record({
    'dateToMillis' : IDL.Nat,
    'granularity' : MetricsGranularity,
    'dateFromMillis' : IDL.Nat,
  });
  const MetricsRequest = IDL.Record({ 'parameters' : GetMetricsParameters });
  const GetInformationRequest = IDL.Record({
    'status' : IDL.Opt(StatusRequest),
    'metrics' : IDL.Opt(MetricsRequest),
    'version' : IDL.Bool,
  });
  const StatusResponse = IDL.Record({
    'memory_size' : IDL.Opt(IDL.Nat64),
    'cycles' : IDL.Opt(IDL.Nat64),
    'heap_memory_size' : IDL.Opt(IDL.Nat64),
  });
  const CanisterMetrics = IDL.Record({ 'data' : CanisterMetricsData });
  const MetricsResponse = IDL.Record({ 'metrics' : IDL.Opt(CanisterMetrics) });
  const GetInformationResponse = IDL.Record({
    'status' : IDL.Opt(StatusResponse),
    'metrics' : IDL.Opt(MetricsResponse),
    'version' : IDL.Opt(IDL.Nat),
  });
  const ConfigPair = IDL.Tuple(ConfigKey, ConfigValue);
  const PartialContinuation = IDL.Tuple(UpdateId, ConfigKey);
  const ConfigFullPartial = IDL.Record({
    'data' : IDL.Vec(ConfigPair),
    'continuation' : IDL.Opt(PartialContinuation),
  });
  const ConfigDeltaPair = IDL.Tuple(ConfigKey, IDL.Opt(ConfigValue));
  const ConfigVariant = IDL.Variant({
    'fullPartial' : ConfigFullPartial,
    'full' : IDL.Vec(ConfigPair),
    'deltaPartial' : IDL.Vec(ConfigDeltaPair),
    'delta' : IDL.Vec(ConfigDeltaPair),
  });
  const ConfigRequestResult = IDL.Record({
    'data' : ConfigVariant,
    'lastRegisteredUpdateId' : UpdateId,
  });
  const ConfigRequestError = IDL.Variant({
    'invalidate' : IDL.Null,
    'wrongToken' : IDL.Null,
    'outdatedReplica' : IDL.Null,
  });
  const GetConfigResult = IDL.Variant({
    'ok' : ConfigRequestResult,
    'err' : ConfigRequestError,
  });
  const ConfigFullVariant = IDL.Variant({
    'fullPartial' : ConfigFullPartial,
    'full' : IDL.Vec(ConfigPair),
  });
  const GetConfigFullResult = IDL.Variant({
    'ok' : ConfigFullVariant,
    'err' : ConfigRequestError,
  });
  const GetConfigFullPartialResult = IDL.Variant({
    'ok' : ConfigFullPartial,
    'err' : ConfigRequestError,
  });
  const SecretPhrase = IDL.Text;
  const ProjectId = IDL.Nat32;
  const ConfigApiPutError = IDL.Variant({
    'limitKeyCount' : IDL.Null,
    'nochange' : IDL.Null,
    'limitKeyLength' : IDL.Null,
    'wrongToken' : IDL.Null,
    'limitValueLength' : IDL.Null,
  });
  const PutResult = IDL.Variant({ 'ok' : IDL.Null, 'err' : ConfigApiPutError });
  const RemoveResult = IDL.Variant({ 'ok' : IDL.Null, 'err' : ConfigApiError });
  const CollectMetricsRequestType = IDL.Variant({
    'force' : IDL.Null,
    'normal' : IDL.Null,
  });
  const UpdateInformationRequest = IDL.Record({
    'metrics' : IDL.Opt(CollectMetricsRequestType),
  });
  return IDL.Service({
    'collectCanisterMetrics' : IDL.Func([], [], []),
    'getAudit' : IDL.Func(
        [AccessToken, AuditRequest],
        [GetAuditResult],
        ['query'],
      ),
    'getCanisterLog' : IDL.Func(
        [IDL.Opt(CanisterLogRequest)],
        [IDL.Opt(CanisterLogResponse)],
        ['query'],
      ),
    'getCanisterMetrics' : IDL.Func(
        [GetMetricsParameters__1],
        [IDL.Opt(CanisterMetrics__1)],
        ['query'],
      ),
    'getCanistergeekInformation' : IDL.Func(
        [GetInformationRequest],
        [GetInformationResponse],
        ['query'],
      ),
    'getConfig' : IDL.Func(
        [AccessToken, UpdateId],
        [GetConfigResult],
        ['query'],
      ),
    'getConfigFull' : IDL.Func([AccessToken], [GetConfigFullResult], ['query']),
    'getConfigFullContinuation' : IDL.Func(
        [AccessToken, PartialContinuation],
        [GetConfigFullPartialResult],
        ['query'],
      ),
    'getMGTInformation' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text))],
        ['query'],
      ),
    'initManagementPrincipal' : IDL.Func([], [IDL.Bool], []),
    'initSecretPhrase' : IDL.Func([SecretPhrase], [], ['oneway']),
    'put' : IDL.Func(
        [ProjectId, IDL.Principal, IDL.Text, ConfigValue],
        [PutResult],
        [],
      ),
    'remove' : IDL.Func(
        [ProjectId, IDL.Principal, IDL.Text],
        [RemoveResult],
        [],
      ),
    'updateCanistergeekInformation' : IDL.Func(
        [UpdateInformationRequest],
        [],
        [],
      ),
  });
};
export const init = ({ IDL }) => { return []; };
