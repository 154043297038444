"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootContentComponent = void 0;
const React = __importStar(require("react"));
const ErrorBoundaryComponent_1 = require("../../common/errorBoundaryComponent/ErrorBoundaryComponent");
const AppRouter_1 = require("src/configeek_assets/src/components/common/AppRouter");
const antd_1 = require("antd");
const react_router_dom_1 = require("react-router-dom");
const PrivateRoute_1 = require("src/configeek_assets/src/components/common/widget/PrivateRoute");
const ProjectsProvider_1 = require("src/configeek_assets/src/components/common/provider/ProjectsProvider");
const ProjectsDataProvider_1 = require("src/configeek_assets/src/components/common/provider/ProjectsDataProvider");
const HomePage_1 = require("src/configeek_assets/src/components/loggedIn/content/home/HomePage");
const Profile_1 = require("src/configeek_assets/src/components/loggedIn/content/profile/Profile");
const ProjectsErrorPage_1 = require("src/configeek_assets/src/components/loggedIn/content/projects/ProjectsErrorPage");
const ProjectsPage_1 = require("src/configeek_assets/src/components/loggedIn/content/projects/ProjectsPage");
const ProjectEntryPoint_1 = require("src/configeek_assets/src/components/loggedIn/content/project/ProjectEntryPoint");
const ProjectUrlProvider_1 = require("src/configeek_assets/src/components/common/provider/ProjectUrlProvider");
const CurrentProjectProvider_1 = require("src/configeek_assets/src/components/common/provider/CurrentProjectProvider");
const Legal_1 = require("src/configeek_assets/src/components/loggedIn/content/legal/Legal");
const AdminTestGg_1 = require("./admin/test_cg/AdminTestGg");
const AdminComponent_1 = require("src/configeek_assets/src/components/loggedIn/content/admin/AdminComponent");
const ProjectPathComponent = () => React.createElement(ProjectUrlProvider_1.ProjectUrlProvider, null,
    React.createElement(CurrentProjectProvider_1.CurrentProjectProvider, null,
        React.createElement(ProjectEntryPoint_1.ProjectEntryPoint, null)));
const WithProjectsCatchingErrorComponent = () => {
    const projectsDataContext = (0, ProjectsDataProvider_1.useProjectsDataContext)();
    if (projectsDataContext.projectsCumulativeInfo.error.isError && projectsDataContext.projectsCumulativeInfo.error.error != undefined) {
        return React.createElement(ProjectsErrorPage_1.ProjectsErrorPage, { error: projectsDataContext.projectsCumulativeInfo.error.error });
    }
    return React.createElement(React.Fragment, null,
        React.createElement(react_router_dom_1.Switch, null,
            React.createElement(PrivateRoute_1.PrivateRoute, { path: AppRouter_1.PATH__PROJECT_ROOT, remounting: true, exactRouteMatchPath: AppRouter_1.PATH__PROJECT_ROOT, component: ProjectPathComponent }),
            React.createElement(PrivateRoute_1.PrivateRoute, { path: AppRouter_1.PATH__PROJECTS, component: ProjectsPage_1.ProjectsPage }),
            React.createElement(react_router_dom_1.Redirect, { from: "*", to: AppRouter_1.PATH__HOME })));
};
const WithProjectsComponent = () => {
    return React.createElement(ProjectsProvider_1.ProjectsProvider, null,
        React.createElement(react_router_dom_1.Switch, null,
            React.createElement(PrivateRoute_1.PrivateRoute, { path: AppRouter_1.PATH__HOME, exact: true, component: HomePage_1.HomePage }),
            React.createElement(PrivateRoute_1.PrivateRoute, { path: AppRouter_1.PATH__PROFILE, component: Profile_1.Profile }),
            React.createElement(WithProjectsCatchingErrorComponent, null)));
};
const RootContentComponent = () => {
    return React.createElement(ErrorBoundaryComponent_1.ErrorBoundaryComponent, { childComponentName: "RootContentComponent" },
        React.createElement(antd_1.Row, { style: { flexGrow: 1 } },
            React.createElement(antd_1.Col, { span: 24, className: "pageContentCol" },
                React.createElement(react_router_dom_1.Switch, null,
                    React.createElement(PrivateRoute_1.PrivateRoute, { path: AppRouter_1.PATH__LEGAL, component: Legal_1.Legal }),
                    !!process.env.IS_TEST_SERVER && React.createElement(PrivateRoute_1.PrivateRoute, { path: AppRouter_1.PATH__ADMIN, component: AdminComponent_1.AdminComponent }),
                    !!process.env.IS_TEST_SERVER && React.createElement(PrivateRoute_1.PrivateRoute, { path: AppRouter_1.PATH__TEST_UG, component: AdminTestGg_1.AdminTestGg }),
                    React.createElement(WithProjectsComponent, null)))));
};
exports.RootContentComponent = RootContentComponent;
