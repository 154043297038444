"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyFilter = void 0;
const antd_1 = require("antd");
const React = __importStar(require("react"));
const react_1 = require("react");
const AuditLogPageModelProvider_1 = require("src/configeek_assets/src/components/loggedIn/content/project/data/audit/model/AuditLogPageModelProvider");
const KeyFilter = () => {
    const pageModelContext = (0, AuditLogPageModelProvider_1.useAuditLogPageModelContext)();
    const jsonModel = pageModelContext.pageModelStateFns.getFormJSONModel();
    const onChange = (0, react_1.useCallback)((e) => {
        pageModelContext.pageModelStateFns.onKeyFilterChanged(e.target.value);
    }, [pageModelContext.pageModelStateFns.onKeyFilterChanged]);
    const onPressEnter = (0, react_1.useCallback)(() => {
        pageModelContext.pageModelStateFns.show();
    }, [pageModelContext.pageModelStateFns.show]);
    const value = jsonModel.getKeyFilter().getValue();
    return React.createElement(React.Fragment, null,
        React.createElement(antd_1.Form.Item, { label: "Filter by Key" },
            React.createElement(antd_1.Input, { value: value, placeholder: "Key", onChange: onChange, allowClear: true, onPressEnter: onPressEnter })));
};
exports.KeyFilter = KeyFilter;
