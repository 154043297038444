"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppAnonymous = void 0;
const React = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const Landing_1 = require("src/configeek_assets/src/components/anonymous/landing/Landing");
const AppRouter_1 = require("src/configeek_assets/src/components/common/AppRouter");
const BrandGuidelines_1 = require("src/configeek_assets/src/components/anonymous/brandGuidelines/BrandGuidelines");
const Legal_1 = require("src/configeek_assets/src/components/anonymous/legal/Legal");
const AppAnonymous = () => {
    return React.createElement(React.Fragment, null,
        React.createElement(react_router_dom_1.Switch, null,
            React.createElement(react_router_dom_1.Route, { path: AppRouter_1.PATH__HOME, exact: true, component: Landing_1.Landing }),
            React.createElement(react_router_dom_1.Route, { path: AppRouter_1.PATH_BRAND_GUIDELINES, exact: true, component: BrandGuidelines_1.BrandGuidelines }),
            React.createElement(react_router_dom_1.Route, { path: AppRouter_1.PATH__LEGAL, exact: true, component: Legal_1.Legal }),
            React.createElement(react_router_dom_1.Redirect, { from: "*", to: AppRouter_1.PATH__HOME })));
};
exports.AppAnonymous = AppAnonymous;
