"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TestServerRenderComponent = exports.SkeletonComponent = void 0;
const React = __importStar(require("react"));
const antd_1 = require("antd");
const ErrorBoundaryComponent_1 = require("src/configeek_assets/src/components/common/errorBoundaryComponent/ErrorBoundaryComponent");
const SkeletonComponent = ({ toolbarComponent: ToolbarComponent, contentComponent: ContentComponent, footerComponent: FooterComponent }) => {
    // const matchLegal = useRouteMatch(PATH__LEGAL);
    const contentColSpan = 22; //matchLegal?.isExact ? 16 : 22
    const contentOuterColSpan = (24 - contentColSpan) / 2;
    return React.createElement(React.Fragment, null,
        React.createElement(ServerStatusComponent, null),
        React.createElement(antd_1.Row, { className: "toolbarRow" },
            React.createElement(antd_1.Col, { span: 1 }),
            React.createElement(antd_1.Col, { span: 22 },
                React.createElement(ToolbarComponent, null)),
            React.createElement(antd_1.Col, { span: 1 })),
        React.createElement(antd_1.Row, { className: "contentRow" },
            React.createElement(antd_1.Col, { span: 1, lg: contentOuterColSpan, className: "contentColLeftSide" }),
            React.createElement(antd_1.Col, { span: 22, lg: contentColSpan, className: "contentCol" },
                React.createElement(ErrorBoundaryComponent_1.ErrorBoundaryComponent, { childComponentName: "RootContentComponent" },
                    React.createElement(ContentComponent, null))),
            React.createElement(antd_1.Col, { span: 1, lg: contentOuterColSpan, className: "contentColRightSide" })),
        FooterComponent ?
            React.createElement(antd_1.Row, { className: "footerRow" },
                React.createElement(antd_1.Col, { span: 1, lg: contentOuterColSpan }),
                React.createElement(antd_1.Col, { span: 22, lg: contentColSpan },
                    React.createElement(FooterComponent, null)),
                React.createElement(antd_1.Col, { span: 1, lg: contentOuterColSpan }))
            : null);
};
exports.SkeletonComponent = SkeletonComponent;
/*type ServerStatusWrapperProps = {
    isTestServer: boolean
}
const ServerStatusComponent = (props: PropsWithChildren<ServerStatusWrapperProps>) => {
    if (props.isTestServer) {
        return <div style={{
            position: "fixed",
            right: 0,
            bottom: 0,
            textAlign: "center",
            backgroundColor: "red",
            color: "white",
            padding: 10,
            width: "100%",
            zIndex: 2,
        }}>!!! TEST SERVER !!!</div>
    }
    return null
}*/
exports.TestServerRenderComponent = !!process.env.IS_TEST_SERVER ? (props) => {
    return React.createElement(React.Fragment, null, props.children);
} : (props) => null;
const ServerStatusComponent = () => React.createElement(exports.TestServerRenderComponent, null,
    React.createElement("div", { style: {
            position: "fixed",
            right: 0,
            bottom: 0,
            textAlign: "center",
            backgroundColor: "rgba(255,0,0,0.5)",
            color: "white",
            padding: 0,
            fontSize: 10,
            width: "100%",
            zIndex: 2,
        } }, "!!! TEST SERVER !!!"));
