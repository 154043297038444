"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OurProductsSection = void 0;
const React = __importStar(require("react"));
const antd_1 = require("antd");
const HeadIcon_1 = require("./HeadIcon");
const constants_1 = require("src/configeek_assets/src/constants");
const OurProductsSection = () => {
    return React.createElement("section", { className: "ug_anonym_section" },
        React.createElement("div", { className: "ug_anonym_sectionContent" },
            React.createElement(antd_1.Row, { justify: "center" },
                React.createElement(antd_1.Col, { span: 22 },
                    React.createElement(antd_1.Row, { justify: "center", className: "pt-5 pb-lg-5" },
                        React.createElement(antd_1.Col, { span: 20 },
                            React.createElement("div", { className: "card-info" },
                                React.createElement("div", { className: "title2" }, "Our Products")))),
                    React.createElement(antd_1.Row, { justify: "center", className: "pb-5", gutter: [16, 16] },
                        React.createElement(antd_1.Col, { span: 12, md: 8 },
                            React.createElement("a", { href: constants_1.URL_STAKEGEEK, target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement("div", null,
                                        React.createElement(HeadIcon_1.StakeGeekIcon, null)),
                                    React.createElement("div", { className: "title35" }, "stakeGeek"),
                                    React.createElement("div", { className: "description" }, "Liquid Staking for ICP."),
                                    React.createElement("div", { className: "description" }, "Stake ICP. Earn rewards. Stay liquid.")))),
                        React.createElement(antd_1.Col, { span: 12, md: 8 },
                            React.createElement("a", { href: constants_1.URL_VPGEEK, target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement("div", null,
                                        React.createElement(HeadIcon_1.VPgeekIcon, null)),
                                    React.createElement("div", { className: "title35" }, "vpGeek"),
                                    React.createElement("div", { className: "description" }, "Internet Computer Voting Power Dashboard."),
                                    React.createElement("div", { className: "description" },
                                        "Monitor each vote. Know every voter.",
                                        React.createElement("br", null),
                                        "Delegate with confidence.")))),
                        React.createElement(antd_1.Col, { span: 12, md: 8 },
                            React.createElement("a", { href: constants_1.URL_IDGEEK, target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement("div", null,
                                        React.createElement(HeadIcon_1.IDgeekIcon, null)),
                                    React.createElement("div", { className: "title35" }, "idGeek"),
                                    React.createElement("div", { className: "description" }, "Internet Identity Marketplace."),
                                    React.createElement("div", { className: "description" },
                                        "Buy or sell Internet Identities",
                                        React.createElement("br", null),
                                        "with their linked assets.")))),
                        React.createElement(antd_1.Col, { span: 12, md: 8 },
                            React.createElement("a", { href: constants_1.URL_CANISTERGEEK, target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement("div", null,
                                        React.createElement(HeadIcon_1.CanistergeekIcon, null)),
                                    React.createElement("div", { className: "title35" }, "canisterGeek"),
                                    React.createElement("div", { className: "description" }, "IC Canister Management."),
                                    React.createElement("div", { className: "description" }, "Top up your canisters, monitor cycles, memory, logs and get your monthly reports in one place.")))),
                        React.createElement(antd_1.Col, { span: 12, md: 8 },
                            React.createElement("a", { href: constants_1.URL_NFTGEEK, target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement("div", null,
                                        React.createElement(HeadIcon_1.NFTgeekIcon, null)),
                                    React.createElement("div", { className: "title35" }, "nftGeek"),
                                    React.createElement("div", { className: "description" }, "IC NFT analytics tool."),
                                    React.createElement("div", { className: "description" }, "Check transactions, minting, holders, listings for IC NFT collections in realtime.")))),
                        React.createElement(antd_1.Col, { span: 12, md: 8 },
                            React.createElement("a", { href: constants_1.URL_USERGEEK, target: "_blank" },
                                React.createElement("div", { className: "card-info" },
                                    React.createElement("div", null,
                                        React.createElement(HeadIcon_1.UsergeekIcon, null)),
                                    React.createElement("div", { className: "title35" }, "userGeek"),
                                    React.createElement("div", { className: "description" }, "Product analytics tool for IC Dapps."),
                                    React.createElement("div", { className: "description" }, "See your product main metrics, such as DAU, WAU, MAU, new users, retention and usage frequency.")))),
                        React.createElement(antd_1.Col, { span: 12, md: 8 },
                            React.createElement("div", { className: "card-info" },
                                React.createElement("div", null,
                                    React.createElement(HeadIcon_1.ConfigeekIcon, null)),
                                React.createElement("div", { className: "title35" }, "confiGeek"),
                                React.createElement("div", { className: "description" }, "Remote Configuration tool."),
                                React.createElement("div", { className: "description" },
                                    "Change appearance and behaviour",
                                    React.createElement("br", null),
                                    "of your product in realtime."))))))));
};
exports.OurProductsSection = OurProductsSection;
