"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuditLogViewJSONModel = void 0;
const GenericNumberValueJSONModel_1 = require("src/configeek_assets/src/components/common/jsonModel/GenericNumberValueJSONModel");
const GenericBooleanValueJSONModel_1 = require("src/configeek_assets/src/components/common/jsonModel/GenericBooleanValueJSONModel");
class AuditLogViewJSONModel {
    constructor(serializedState) {
        this.updateIdJSONModel = new GenericNumberValueJSONModel_1.GenericNumberValueJSONModel();
        this.newLinesVisibleJSONModel = new GenericBooleanValueJSONModel_1.GenericBooleanValueJSONModel();
        this.reset = () => {
            this.updateIdJSONModel.reset();
            this.newLinesVisibleJSONModel.reset();
        };
        this.overwriteFromSerializedState = (serializedState) => {
            if (serializedState) {
                this.updateIdJSONModel.overwriteFromSerializedState(serializedState.updateId);
                this.newLinesVisibleJSONModel.overwriteFromSerializedState(serializedState.newLinesVisible);
            }
        };
        this.serialize = () => {
            return {
                updateId: this.updateIdJSONModel.serialize(),
                newLinesVisible: this.newLinesVisibleJSONModel.serialize(),
            };
        };
        this.getUpdateId = () => this.updateIdJSONModel;
        this.getNewLinesVisible = () => this.newLinesVisibleJSONModel;
        this.reset();
        this.overwriteFromSerializedState(serializedState);
    }
}
exports.AuditLogViewJSONModel = AuditLogViewJSONModel;
