"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectsErrorPage = void 0;
const React = __importStar(require("react"));
const antd_1 = require("antd");
const ProjectsDataProvider_1 = require("src/configeek_assets/src/components/common/provider/ProjectsDataProvider");
const PageContent_1 = require("src/configeek_assets/src/components/common/widget/PageContent");
const i18_1 = require("src/configeek_assets/src/i18");
const ProjectsErrorPage = (props) => {
    const projectsDataContext = (0, ProjectsDataProvider_1.useProjectsDataContext)();
    const onClickTryAgain = async () => {
        projectsDataContext.fetchProjects();
    };
    const subTitle = React.createElement(React.Fragment, null,
        "Projects data cannot be loaded.",
        React.createElement("br", null),
        "Error: ",
        props.error.message,
        React.createElement("br", null),
        "Please try again.");
    const inProgress = projectsDataContext.projectsCumulativeInfo.status.inProgress;
    return React.createElement(React.Fragment, null,
        React.createElement(PageContent_1.PageContent, null,
            React.createElement(PageContent_1.PageContent.CardSpacer, null),
            React.createElement(PageContent_1.PageContent.Card, null,
                React.createElement(antd_1.Result, { title: i18_1.i18.common.error.oops.title, subTitle: subTitle, icon: React.createElement(React.Fragment, null), extra: React.createElement(antd_1.Button, { type: "primary", size: "large", onClick: onClickTryAgain, disabled: inProgress, loading: inProgress }, i18_1.i18.common.error.tryAgain) }))));
};
exports.ProjectsErrorPage = ProjectsErrorPage;
