"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectSettingsPage = void 0;
const React = __importStar(require("react"));
const antd_1 = require("antd");
const PageContent_1 = require("src/configeek_assets/src/components/common/widget/PageContent");
const AppRouter_1 = require("src/configeek_assets/src/components/common/AppRouter");
const CurrentProjectProvider_1 = require("src/configeek_assets/src/components/common/provider/CurrentProjectProvider");
const DateTimeUtils_1 = require("src/configeek_assets/src/system/mics/DateTimeUtils");
const ProjectSettingsPage = () => {
    const currentProjectContext = (0, CurrentProjectProvider_1.useCurrentProjectContext)();
    const projectView = currentProjectContext.projectData.view.project;
    const projectApiKey = projectView.projectApiKey;
    const projectCreationDateTime = DateTimeUtils_1.DateTimeUtils.formatDate(projectView.createdTimeSeconds * 1000, "dayTimeSeconds");
    return React.createElement(React.Fragment, null,
        React.createElement(antd_1.PageHeader, { title: "Settings" }),
        React.createElement(PageContent_1.PageContent, null,
            React.createElement(PageContent_1.PageContent.CardSpacer, null),
            React.createElement(PageContent_1.PageContent.Card, null,
                React.createElement(antd_1.Typography.Paragraph, null,
                    "Api Key: ",
                    React.createElement(antd_1.Typography.Text, { copyable: true, className: "apiKey" }, projectApiKey)),
                React.createElement(antd_1.Typography.Paragraph, null,
                    "Created: ",
                    projectCreationDateTime,
                    " UTC"),
                React.createElement(antd_1.Typography.Paragraph, null,
                    "Please find integration instructions on the ",
                    React.createElement("a", { href: AppRouter_1.PathGenerator.github.home(), target: "_blank", style: { textDecoration: "underline" } }, "Github")))));
};
exports.ProjectSettingsPage = ProjectSettingsPage;
