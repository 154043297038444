"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSharedFunctionDataPrincipal = exports.ICRestError = exports.getICFirstKey = exports.getICRequestErrName = exports.getICOptional = exports.createICOptional = exports.isICRequestErrWithName = exports.createErrRestart = exports.createErrRetry = exports.createErrFatal = exports.createErrResult = exports.createOkResult = exports.isErr = exports.isOk = void 0;
const typescriptAddons_1 = require("src/configeek_assets/src/system/typescriptAddons");
const okResultKey = "ok";
const errResultKey = "err";
const isOk = (obj) => {
    return (0, typescriptAddons_1.hasOwnProperty)(obj, okResultKey);
};
exports.isOk = isOk;
const isErr = (obj) => {
    return (0, typescriptAddons_1.hasOwnProperty)(obj, errResultKey);
};
exports.isErr = isErr;
const createOkResult = (value) => {
    return { [okResultKey]: value };
};
exports.createOkResult = createOkResult;
const createErrResult = (value) => {
    return { [errResultKey]: value };
};
exports.createErrResult = createErrResult;
const createErrFatal = () => {
    return (0, exports.createErrResult)("fatal");
};
exports.createErrFatal = createErrFatal;
const createErrRetry = () => {
    return (0, exports.createErrResult)("retry");
};
exports.createErrRetry = createErrRetry;
const createErrRestart = () => {
    return (0, exports.createErrResult)("restart");
};
exports.createErrRestart = createErrRestart;
const isICRequestErrWithName = (obj, name) => {
    return (0, typescriptAddons_1.hasOwnProperty)(obj, name);
};
exports.isICRequestErrWithName = isICRequestErrWithName;
function createICOptional(value) {
    return value != undefined ? [value] : [];
}
exports.createICOptional = createICOptional;
function getICOptional(value) {
    return value != undefined ?
        value.length === 1 ? value[0] : undefined
        : undefined;
}
exports.getICOptional = getICOptional;
const getICRequestErrName = (obj) => {
    // @ts-ignore
    return Object.keys(obj.err)[0];
};
exports.getICRequestErrName = getICRequestErrName;
const getICFirstKey = (obj) => {
    return Object.keys(obj)[0];
};
exports.getICFirstKey = getICFirstKey;
class ICRestError {
    constructor(message) {
        this.toString = () => {
            return `ICRestError (message: ${this.message})`;
        };
        this.message = message;
    }
    static withUnknownError(e) {
        const error = e instanceof ICRestError ? e : ICRestError.withNativeError(e);
        return error;
    }
    static withNativeError(error) {
        return new ICRestError(error.message);
    }
    toNativeError() {
        return new Error(this.message);
    }
}
exports.ICRestError = ICRestError;
const getSharedFunctionDataPrincipal = (value) => {
    try {
        if (Array.isArray(value)) {
            const data = value;
            if (data.length === 2) {
                const principal = data[0];
                if (typeof principal["toText"] != "function") {
                    console.warn("util.getSharedFunctionDataPrincipal: bad principal object", principal);
                }
                return principal;
            }
        }
    }
    catch (e) {
        console.warn("util.getSharedFunctionDataPrincipal", e);
    }
    return undefined;
};
exports.getSharedFunctionDataPrincipal = getSharedFunctionDataPrincipal;
