"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JSONUtils = void 0;
const parseJSON = (value) => {
    try {
        return JSON.parse(value || "");
    }
    catch (e) {
    }
    return undefined;
};
const isJSONValid = (value) => {
    return parseJSON(value) != undefined;
};
const minifyJSON = (value) => {
    return JSON.stringify(parseJSON(value));
};
const beautifyJSON = (value, space = 2) => {
    return JSON.stringify(parseJSON(value), null, space);
};
exports.JSONUtils = {
    isJSONValid: isJSONValid,
    minifyJSON: minifyJSON,
    beautifyJSON: beautifyJSON,
};
