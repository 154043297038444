export const idlFactory = ({ IDL }) => {
  const ProjectApiKey = IDL.Text;
  const ProjectRole = IDL.Variant({
    'admin' : IDL.Null,
    'owner' : IDL.Null,
    'viewer' : IDL.Null,
  });
  const ParticipantName = IDL.Text;
  const AddNewParticipantValidationError = IDL.Variant({
    'participantNameEmpty' : IDL.Null,
    'participantNameTooLong' : IDL.Null,
  });
  const AddNewParticipantError = IDL.Variant({
    'validationError' : AddNewParticipantValidationError,
    'participantCountLimit' : IDL.Null,
    'notAccess' : IDL.Null,
    'projectNotFound' : IDL.Null,
    'participantExists' : IDL.Null,
    'temporarilyUnavailable' : IDL.Null,
  });
  const AddNewParticipantResult = IDL.Variant({
    'ok' : IDL.Null,
    'err' : AddNewParticipantError,
  });
  const ProjectName = IDL.Text;
  const CreateProjectValidationError = IDL.Variant({
    'registryError' : IDL.Null,
    'projectNameTooLong' : IDL.Null,
    'anonymousAccessDenied' : IDL.Null,
    'projectNameEmpty' : IDL.Null,
  });
  const CreateProjectError = IDL.Variant({
    'validationError' : CreateProjectValidationError,
    'temporarilyUnavailable' : IDL.Null,
    'projectCountLimit' : IDL.Null,
  });
  const CreateProjectResult = IDL.Variant({
    'ok' : ProjectApiKey,
    'err' : CreateProjectError,
  });
  const GetLogMessagesFilter = IDL.Record({
    'analyzeCount' : IDL.Nat32,
    'messageRegex' : IDL.Opt(IDL.Text),
    'messageContains' : IDL.Opt(IDL.Text),
  });
  const Nanos = IDL.Nat64;
  const GetLogMessagesParameters = IDL.Record({
    'count' : IDL.Nat32,
    'filter' : IDL.Opt(GetLogMessagesFilter),
    'fromTimeNanos' : IDL.Opt(Nanos),
  });
  const GetLatestLogMessagesParameters = IDL.Record({
    'upToTimeNanos' : IDL.Opt(Nanos),
    'count' : IDL.Nat32,
    'filter' : IDL.Opt(GetLogMessagesFilter),
  });
  const CanisterLogRequest = IDL.Variant({
    'getMessagesInfo' : IDL.Null,
    'getMessages' : GetLogMessagesParameters,
    'getLatestMessages' : GetLatestLogMessagesParameters,
  });
  const CanisterLogFeature = IDL.Variant({
    'filterMessageByContains' : IDL.Null,
    'filterMessageByRegex' : IDL.Null,
  });
  const CanisterLogMessagesInfo = IDL.Record({
    'features' : IDL.Vec(IDL.Opt(CanisterLogFeature)),
    'lastTimeNanos' : IDL.Opt(Nanos),
    'count' : IDL.Nat32,
    'firstTimeNanos' : IDL.Opt(Nanos),
  });
  const LogMessagesData = IDL.Record({
    'timeNanos' : Nanos,
    'message' : IDL.Text,
  });
  const CanisterLogMessages = IDL.Record({
    'data' : IDL.Vec(LogMessagesData),
    'lastAnalyzedMessageTimeNanos' : IDL.Opt(Nanos),
  });
  const CanisterLogResponse = IDL.Variant({
    'messagesInfo' : CanisterLogMessagesInfo,
    'messages' : CanisterLogMessages,
  });
  const MetricsGranularity = IDL.Variant({
    'hourly' : IDL.Null,
    'daily' : IDL.Null,
  });
  const GetMetricsParameters__1 = IDL.Record({
    'dateToMillis' : IDL.Nat,
    'granularity' : MetricsGranularity,
    'dateFromMillis' : IDL.Nat,
  });
  const UpdateCallsAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterHeapMemoryAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterCyclesAggregatedData = IDL.Vec(IDL.Nat64);
  const CanisterMemoryAggregatedData = IDL.Vec(IDL.Nat64);
  const HourlyMetricsData = IDL.Record({
    'updateCalls' : UpdateCallsAggregatedData,
    'canisterHeapMemorySize' : CanisterHeapMemoryAggregatedData,
    'canisterCycles' : CanisterCyclesAggregatedData,
    'canisterMemorySize' : CanisterMemoryAggregatedData,
    'timeMillis' : IDL.Int,
  });
  const NumericEntity = IDL.Record({
    'avg' : IDL.Nat64,
    'max' : IDL.Nat64,
    'min' : IDL.Nat64,
    'first' : IDL.Nat64,
    'last' : IDL.Nat64,
  });
  const DailyMetricsData = IDL.Record({
    'updateCalls' : IDL.Nat64,
    'canisterHeapMemorySize' : NumericEntity,
    'canisterCycles' : NumericEntity,
    'canisterMemorySize' : NumericEntity,
    'timeMillis' : IDL.Int,
  });
  const CanisterMetricsData = IDL.Variant({
    'hourly' : IDL.Vec(HourlyMetricsData),
    'daily' : IDL.Vec(DailyMetricsData),
  });
  const CanisterMetrics__1 = IDL.Record({ 'data' : CanisterMetricsData });
  const StatusRequest = IDL.Record({
    'memory_size' : IDL.Bool,
    'cycles' : IDL.Bool,
    'heap_memory_size' : IDL.Bool,
  });
  const GetMetricsParameters = IDL.Record({
    'dateToMillis' : IDL.Nat,
    'granularity' : MetricsGranularity,
    'dateFromMillis' : IDL.Nat,
  });
  const MetricsRequest = IDL.Record({ 'parameters' : GetMetricsParameters });
  const GetInformationRequest = IDL.Record({
    'status' : IDL.Opt(StatusRequest),
    'metrics' : IDL.Opt(MetricsRequest),
    'version' : IDL.Bool,
  });
  const StatusResponse = IDL.Record({
    'memory_size' : IDL.Opt(IDL.Nat64),
    'cycles' : IDL.Opt(IDL.Nat64),
    'heap_memory_size' : IDL.Opt(IDL.Nat64),
  });
  const CanisterMetrics = IDL.Record({ 'data' : CanisterMetricsData });
  const MetricsResponse = IDL.Record({ 'metrics' : IDL.Opt(CanisterMetrics) });
  const GetInformationResponse = IDL.Record({
    'status' : IDL.Opt(StatusResponse),
    'metrics' : IDL.Opt(MetricsResponse),
    'version' : IDL.Opt(IDL.Nat),
  });
  const ParticipantView = IDL.Record({
    'principal' : IDL.Principal,
    'name' : ParticipantName,
    'role' : ProjectRole,
    'createdTimeSeconds' : IDL.Nat32,
  });
  const ProjectView = IDL.Record({
    'projectName' : ProjectName,
    'projectApiKey' : ProjectApiKey,
    'createdTimeSeconds' : IDL.Nat32,
  });
  const ProjectParticipantView = IDL.Record({
    'participant' : ParticipantView,
    'project' : ProjectView,
  });
  const GetParticipantProjectsError = IDL.Variant({
    'temporarilyUnavailable' : IDL.Null,
  });
  const GetParticipantProjectsResult = IDL.Variant({
    'ok' : IDL.Vec(ProjectParticipantView),
    'err' : GetParticipantProjectsError,
  });
  const GetProjectParticipantsError = IDL.Variant({
    'notAccess' : IDL.Null,
    'projectNotFound' : IDL.Null,
    'temporarilyUnavailable' : IDL.Null,
  });
  const GetProjectParticipantsResult = IDL.Variant({
    'ok' : IDL.Vec(ParticipantView),
    'err' : GetProjectParticipantsError,
  });
  const RegistryDefinition = IDL.Tuple(IDL.Text, IDL.Nat, IDL.Nat);
  const SecretPhrase = IDL.Text;
  const ConfigStoreDefinition = IDL.Tuple(IDL.Text, IDL.Bool);
  const ConfigurationRequest = IDL.Variant({
    'initRegistries' : IDL.Vec(RegistryDefinition),
    'initSecretPhrase' : SecretPhrase,
    'initConfigStores' : IDL.Vec(ConfigStoreDefinition),
  });
  const AccessToken = IDL.Text;
  const IssueAuditAccessTokenError = IDL.Variant({
    'notAccess' : IDL.Null,
    'projectNotFound' : IDL.Null,
    'temporarilyUnavailable' : IDL.Null,
  });
  const IssueAuditAccessTokenResult = IDL.Variant({
    'ok' : AccessToken,
    'err' : IssueAuditAccessTokenError,
  });
  const ConfigValue = IDL.Variant({ 'none' : IDL.Null, 'text' : IDL.Text });
  const PutProjectValueValidationError = IDL.Variant({
    'tooManyKeys' : IDL.Null,
    'keyValueTooLong' : IDL.Null,
    'unchangedValue' : IDL.Null,
    'keyNameTooLong' : IDL.Null,
  });
  const PutProjectValueError = IDL.Variant({
    'validationError' : PutProjectValueValidationError,
    'notAccess' : IDL.Null,
    'projectNotFound' : IDL.Null,
    'temporarilyUnavailable' : IDL.Null,
    'configStoreLocateError' : IDL.Null,
  });
  const PutProjectValueResult = IDL.Variant({
    'ok' : IDL.Null,
    'err' : PutProjectValueError,
  });
  const RemoveParticipantError = IDL.Variant({
    'notAccess' : IDL.Null,
    'projectNotFound' : IDL.Null,
    'temporarilyUnavailable' : IDL.Null,
    'participantNotFound' : IDL.Null,
  });
  const RemoveParticipantResult = IDL.Variant({
    'ok' : IDL.Null,
    'err' : RemoveParticipantError,
  });
  const RemoveProjectValueError = IDL.Variant({
    'notAccess' : IDL.Null,
    'projectNotFound' : IDL.Null,
    'temporarilyUnavailable' : IDL.Null,
    'configStoreLocateError' : IDL.Null,
  });
  const RemoveProjectValueResult = IDL.Variant({
    'ok' : IDL.Null,
    'err' : RemoveProjectValueError,
  });
  const CollectMetricsRequestType = IDL.Variant({
    'force' : IDL.Null,
    'normal' : IDL.Null,
  });
  const UpdateInformationRequest = IDL.Record({
    'metrics' : IDL.Opt(CollectMetricsRequestType),
  });
  const UpdateParticipantNameValidationError = IDL.Variant({
    'participantNameEmpty' : IDL.Null,
    'participantNameTooLong' : IDL.Null,
  });
  const UpdateParticipantNameError = IDL.Variant({
    'validationError' : UpdateParticipantNameValidationError,
    'notAccess' : IDL.Null,
    'projectNotFound' : IDL.Null,
    'temporarilyUnavailable' : IDL.Null,
    'participantNotFound' : IDL.Null,
  });
  const UpdateParticipantNameResult = IDL.Variant({
    'ok' : IDL.Null,
    'err' : UpdateParticipantNameError,
  });
  const UpdateProjectNameValidationError = IDL.Variant({
    'projectNameTooLong' : IDL.Null,
    'projectNameEmpty' : IDL.Null,
  });
  const UpdateProjectNameError = IDL.Variant({
    'validationError' : UpdateProjectNameValidationError,
    'notAccess' : IDL.Null,
    'projectNotFound' : IDL.Null,
    'temporarilyUnavailable' : IDL.Null,
  });
  const UpdateProjectNameResult = IDL.Variant({
    'ok' : IDL.Null,
    'err' : UpdateProjectNameError,
  });
  return IDL.Service({
    'addNewParticipant' : IDL.Func(
        [ProjectApiKey, IDL.Text, ProjectRole, ParticipantName],
        [AddNewParticipantResult],
        [],
      ),
    'addNewViewerParticipant' : IDL.Func(
        [ProjectApiKey, IDL.Text, ParticipantName],
        [AddNewParticipantResult],
        [],
      ),
    'collectCanisterMetrics' : IDL.Func([], [], []),
    'createProject' : IDL.Func([ProjectName], [CreateProjectResult], []),
    'getCanisterLog' : IDL.Func(
        [IDL.Opt(CanisterLogRequest)],
        [IDL.Opt(CanisterLogResponse)],
        ['query'],
      ),
    'getCanisterMetrics' : IDL.Func(
        [GetMetricsParameters__1],
        [IDL.Opt(CanisterMetrics__1)],
        ['query'],
      ),
    'getCanistergeekInformation' : IDL.Func(
        [GetInformationRequest],
        [GetInformationResponse],
        ['query'],
      ),
    'getParticipantProjects' : IDL.Func(
        [],
        [GetParticipantProjectsResult],
        ['query'],
      ),
    'getProjectParticipants' : IDL.Func(
        [ProjectApiKey],
        [GetProjectParticipantsResult],
        ['query'],
      ),
    'init' : IDL.Func([IDL.Vec(ConfigurationRequest)], [IDL.Bool], []),
    'issueAuditAccessToken' : IDL.Func(
        [ProjectApiKey],
        [IssueAuditAccessTokenResult],
        ['query'],
      ),
    'mgt_getInformation' : IDL.Func([], [IDL.Text], []),
    'mgt_getTechInformation' : IDL.Func([], [IDL.Text], []),
    'mgt_hasAccess' : IDL.Func([], [IDL.Bool], ['query']),
    'putProjectValue' : IDL.Func(
        [ProjectApiKey, IDL.Text, ConfigValue],
        [PutProjectValueResult],
        [],
      ),
    'reinit' : IDL.Func([IDL.Vec(ConfigurationRequest)], [], []),
    'removeParticipant' : IDL.Func(
        [ProjectApiKey, IDL.Text],
        [RemoveParticipantResult],
        [],
      ),
    'removeProjectValue' : IDL.Func(
        [ProjectApiKey, IDL.Text],
        [RemoveProjectValueResult],
        [],
      ),
    'updateCanistergeekInformation' : IDL.Func(
        [UpdateInformationRequest],
        [],
        [],
      ),
    'updateParticipantName' : IDL.Func(
        [ProjectApiKey, IDL.Text, ParticipantName],
        [UpdateParticipantNameResult],
        [],
      ),
    'updateProjectName' : IDL.Func(
        [ProjectApiKey, ProjectName],
        [UpdateProjectNameResult],
        [],
      ),
  });
};
export const init = ({ IDL }) => { return []; };
