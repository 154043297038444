"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDgeekIconSVG = void 0;
const React = __importStar(require("react"));
const IDgeekIconSVG = () => React.createElement("svg", { width: "1em", height: "1em", version: "1.1", viewBox: "0 0 504 576", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { transform: "matrix(2.2259 0 0 2.2259 -308.87 -353.06)", fill: "currentColor" },
        React.createElement("polygon", { points: "341 412.3 162.9 412.3 162.9 333.8 179.4 333.8 179.4 395.8 324.5 395.8 324.5 333.8 341 333.8" }),
        React.createElement("path", { d: "m340.8 317.5v-69.6h-69.6v28.3h-38.6v-28.3h-69.6v69.6h69.6v-24.8h38.5v49.1h-46.5v16.5h63v-40.9h53.2zm-52-51.9h36v36h-36zm-72 36.5h-36v-36.9h36z" }),
        React.createElement("path", { d: "m221.3 179v-15.3h-58.2v70.1h58.2v-18.8h11.4v-17.7h38.6v17.7h16.2v-17.7h6.5v17.7h16.1v-17.7h14.7v37.9h16.2v-56.2zm-15.3 38h-29.7v-36.5h29.7z" })));
exports.IDgeekIconSVG = IDgeekIconSVG;
