"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectModalComponent = void 0;
const React = __importStar(require("react"));
const react_1 = require("react");
const antd_1 = require("antd");
const Form_1 = require("antd/lib/form/Form");
const ProjectsDataProvider_1 = require("src/configeek_assets/src/components/common/provider/ProjectsDataProvider");
const promiseUtils_1 = require("src/configeek_assets/src/system/mics/promiseUtils");
const ICCanisterUtils_1 = require("src/configeek_assets/src/ic/ICCanisterUtils");
const i18_1 = require("src/configeek_assets/src/i18");
const Analytics_1 = require("src/configeek_assets/src/system/analytics/Analytics");
const limits_1 = require("src/configeek_assets/src/limits");
const formRules = {
    name: [{ required: true, message: "Name is required" }]
};
const modalButtonsPropsInitialValue = {
    ok: { disabled: true },
    cancel: {}
};
const ProjectModalComponent = (props) => {
    var _a;
    const projectsDataContext = (0, ProjectsDataProvider_1.useProjectsDataContext)();
    const [form] = (0, Form_1.useForm)();
    const [modalButtonProps, setModalButtonProps] = (0, react_1.useReducer)((state, newState) => ({ ...state, ...newState }), modalButtonsPropsInitialValue);
    const isCreate = props.projectView == undefined;
    const initialValues = { name: ((_a = props.projectView) === null || _a === void 0 ? void 0 : _a.projectName) || "" };
    const title = isCreate ? `Create Project` : "Rename Project";
    const okText = isCreate ? "Create" : "Rename";
    const errorText = isCreate ? i18_1.i18.projects.error.modal.create : i18_1.i18.projects.error.modal.rename;
    const createCancellable = (0, react_1.useMemo)(() => (0, promiseUtils_1.makeCancelableAsync)(async (projectName) => {
        const createProjectResult = await projectsDataContext.createProject(projectName);
        if (createProjectResult.status === "success") {
            Analytics_1.Analytics.trackCreateProject();
            projectsDataContext.fetchProjects();
        }
        return createProjectResult;
    }), []);
    const renameCancellable = (0, react_1.useMemo)(() => (0, promiseUtils_1.makeCancelableAsync)(async (projectApiKey, projectName) => {
        const renameProjectResult = await projectsDataContext.renameProject(projectApiKey, projectName);
        if (renameProjectResult.status === "success") {
            projectsDataContext.fetchProjects();
        }
        return renameProjectResult;
    }), []);
    const asyncCallCreate = async (formValues) => {
        try {
            const { name } = formValues;
            //disabled buttons
            setModalButtonProps({
                ok: { disabled: true, loading: true, },
                cancel: { disabled: true, }
            });
            const result = await createCancellable.run(name);
            switch (result.state) {
                case "success": {
                    const createProjectResult = result.result;
                    if (!!process.env.IS_TEST_SERVER) {
                        console.log("asyncCallCreate createCancellable result", createProjectResult);
                    }
                    switch (createProjectResult.status) {
                        case "success": {
                            props.onDestroy();
                            break;
                        }
                        case "error": {
                            // const reason = createProjectResult.reason;
                            //enabled buttons
                            setModalButtonProps({
                                ok: { disabled: false, loading: false, },
                                cancel: { disabled: false, }
                            });
                            //update errors
                            form && form.setFields([{ name: "name", errors: [errorText] }]);
                            break;
                        }
                        case "unknownError": {
                            throw createProjectResult.error;
                        }
                    }
                    break;
                }
                case "cancelled": {
                    //skip
                    break;
                }
                case "error": {
                    // noinspection ExceptionCaughtLocallyJS
                    throw result.error;
                }
            }
        }
        catch (e) {
            const error = ICCanisterUtils_1.ICRestError.withUnknownError(e);
            console.error("ProjectModalComponent: asyncCallCreate caught", error);
            //enabled buttons
            setModalButtonProps({
                ok: { disabled: false, loading: false, },
                cancel: { disabled: false, }
            });
            //update errors
            form && form.setFields([{ name: "name", errors: [errorText] }]);
        }
    };
    const asyncCallRename = async (formValues) => {
        var _a;
        try {
            const { name } = formValues;
            //disabled buttons
            setModalButtonProps({
                ok: { disabled: true, loading: true, },
                cancel: { disabled: true, }
            });
            const result = await renameCancellable.run((_a = props.projectView) === null || _a === void 0 ? void 0 : _a.projectApiKey, name);
            switch (result.state) {
                case "success": {
                    const renameProjectResult = result.result;
                    if (!!process.env.IS_TEST_SERVER) {
                        console.log("asyncCallRename renameCancellable result", renameProjectResult);
                    }
                    switch (renameProjectResult.status) {
                        case "success": {
                            props.onDestroy();
                            break;
                        }
                        case "error": {
                            // const reason = renameProjectResult.reason;
                            //enabled buttons
                            setModalButtonProps({
                                ok: { disabled: false, loading: false, },
                                cancel: { disabled: false, }
                            });
                            //update errors
                            form && form.setFields([{ name: "name", errors: [errorText] }]);
                            break;
                        }
                        case "unknownError": {
                            // noinspection ExceptionCaughtLocallyJS
                            throw renameProjectResult.error;
                        }
                    }
                    break;
                }
                case "cancelled": {
                    //skip
                    break;
                }
                case "error": {
                    // noinspection ExceptionCaughtLocallyJS
                    throw result.error;
                }
            }
        }
        catch (e) {
            const error = ICCanisterUtils_1.ICRestError.withUnknownError(e);
            console.error("ProjectModalComponent: asyncCallRename caught", error);
            //enabled buttons
            setModalButtonProps({
                ok: { disabled: false, loading: false, },
                cancel: { disabled: false, }
            });
            //update errors
            form && form.setFields([{ name: "name", errors: [errorText] }]);
        }
    };
    const onFormFieldsChange = (changedFields, allFields) => {
        let valid = true;
        for (const idx in allFields) {
            const field = allFields[idx];
            if (field.errors && field.errors.length > 0) {
                valid = false;
            }
        }
        setModalButtonProps({
            ok: { disabled: !valid }
        });
    };
    const onFormFinish = async (values) => {
        if (isCreate) {
            await asyncCallCreate(values);
        }
        else {
            await asyncCallRename(values);
        }
    };
    const onCancel = () => {
        props.onDestroy();
    };
    const onOk = async () => {
        if (form) {
            const values = form.getFieldsValue();
            await onFormFinish(values);
        }
    };
    return React.createElement(antd_1.Modal, { closable: false, maskClosable: false, destroyOnClose: true, visible: props.visible, title: title, onCancel: onCancel, okText: okText, onOk: onOk, okButtonProps: modalButtonProps.ok, cancelButtonProps: modalButtonProps.cancel, className: "ug-modal" },
        React.createElement(antd_1.Form, { form: form, layout: "vertical", requiredMark: false, initialValues: initialValues, onFinish: onFormFinish, onFieldsChange: onFormFieldsChange },
            React.createElement(antd_1.Form.Item, { name: "name", label: "Name", rules: formRules.name },
                React.createElement(antd_1.Input, { autoFocus: true, maxLength: limits_1.PROJECT_NAME_MAXLENGTH }))));
};
exports.ProjectModalComponent = ProjectModalComponent;
