"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Analytics = void 0;
const usergeek_ic_js_1 = require("usergeek-ic-js");
const isDevelopment = process.env.NODE_ENV === "development";
const trackEventToConsumer = (eventName) => {
    if (!isDevelopment) {
        usergeek_ic_js_1.Usergeek.trackEvent(eventName);
        usergeek_ic_js_1.Usergeek.flush();
    }
    else {
        if (!!process.env.IS_TEST_SERVER) {
            console.log("Analytics: ", eventName);
        }
        usergeek_ic_js_1.Usergeek.trackEvent(eventName);
        usergeek_ic_js_1.Usergeek.flush();
    }
};
exports.Analytics = {
    trackCreateProject: () => trackEventToConsumer("Create Project"),
    trackCreateParameter: () => trackEventToConsumer("Create Parameter"),
    trackEditParameter: () => trackEventToConsumer("Edit Parameter"),
    trackRemoveParameter: () => trackEventToConsumer("Remove Parameter"),
    trackInviteMember: () => trackEventToConsumer("Invite Member"),
    trackViewParametersPage: () => trackEventToConsumer("View Parameters Page"),
    trackViewAuditPage: () => trackEventToConsumer("View Audit Page"),
};
