"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NFTgeekIconSVG = void 0;
const React = __importStar(require("react"));
const NFTgeekIconSVG = () => React.createElement("svg", { width: "1em", height: "1em", version: "1.0", viewBox: "0 0 500 500", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { fill: "currentColor" },
        React.createElement("polygon", { points: "145.3,107.7 164.4,163.1 190.3,163.1 231.2,88.1 238.1,163.2 263.8,163.4 330,41.4 335.4,163.1 361.2,163.1\n\t\t410.6,72 411.2,71.1 385.9,71.1 356.1,125.8 350.6,8 323.4,8 257.4,129.7 250.6,56.7 222.7,56.7 179.2,137 161.4,85.5 132.2,85.5\n\t\t90.1,163.1 115.2,163.1" }),
        React.createElement("polygon", { points: "380.1,462.3 118.7,462.3 118.7,350.6 89.8,350.6 89.8,493 410.8,493 410.8,350.6 380.1,350.6 \t" }),
        React.createElement("path", { d: "M157.8,322.7c29.1,0,73.2-30.9,92.7-45.6c8.3,6.3,34.4,24,34.4,24v63.4h-84.2v31.1h115.4v-79.2c9.9,3.8,19.1,6.2,27.1,6.2\n\t\tc39.6,0,71.9-28.5,71.9-63.6s-32.3-63.6-71.9-63.6c-29.1,0-73.2,30.9-92.7,45.6c-19.5-14.8-63.7-45.6-92.7-45.6\n\t\tc-39.6,0-71.9,28.5-71.9,63.6C85.9,294.2,118.2,322.7,157.8,322.7z M272.9,259.1L272.9,259.1c22.9-16.8,54.1-35.8,70.3-35.8\n\t\tc24.4,0,44.2,16.1,44.2,35.9c0,19.8-19.8,35.9-44.2,35.9C327.1,295,295.9,275.9,272.9,259.1L272.9,259.1z M157.8,223.2\n\t\tc16,0,47.3,19,70.3,35.8l-0.1,0.1l0.1,0.1c-23,16.8-54.2,35.8-70.3,35.8c-24.4,0-44.2-16.1-44.2-35.9\n\t\tC113.6,239.3,133.5,223.2,157.8,223.2z" })));
exports.NFTgeekIconSVG = NFTgeekIconSVG;
