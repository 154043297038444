"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.URL_MAIL_SUPPORT = exports.MAIL_SUPPORT = exports.URL_GITHUB = exports.URL_TWITTER = exports.URL_DISCORD = exports.URL_IC_ROOT_DOMAIN = exports.URL_GEEKFACTORY = exports.URL_STAKEGEEK = exports.URL_VPGEEK = exports.URL_IDGEEK = exports.URL_NFTGEEK = exports.URL_CANISTERGEEK = exports.URL_USERGEEK = exports.URL_CONFIGEEK = void 0;
exports.URL_CONFIGEEK = "https://configeek.app";
exports.URL_USERGEEK = "https://usergeek.app";
exports.URL_CANISTERGEEK = "https://canistergeek.app";
exports.URL_NFTGEEK = "https://nftgeek.app";
exports.URL_IDGEEK = "https://idgeek.app";
exports.URL_VPGEEK = "https://vpgeek.app";
exports.URL_STAKEGEEK = "https://stakegeek.app";
exports.URL_GEEKFACTORY = "https://geekfactory.app";
exports.URL_IC_ROOT_DOMAIN = "https://appyb-baaaa-aaaah-qc32q-cai.raw.ic0.app/";
exports.URL_DISCORD = "https://discord.gg/CvTpv2TeKs";
exports.URL_TWITTER = "https://twitter.com/_GeekFactory";
exports.URL_GITHUB = "https://github.com/usergeek/configeek-ic-js";
exports.MAIL_SUPPORT = "support@usergeek.com";
exports.URL_MAIL_SUPPORT = `mailto:${exports.MAIL_SUPPORT}`;
