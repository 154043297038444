"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveProjectConfigKeyModalComponent = void 0;
const React = __importStar(require("react"));
const react_1 = require("react");
const antd_1 = require("antd");
const ProjectConfigStoreDataProvider_1 = require("src/configeek_assets/src/components/common/provider/ProjectConfigStoreDataProvider");
const promiseUtils_1 = require("src/configeek_assets/src/system/mics/promiseUtils");
const TextTruncated_1 = require("src/configeek_assets/src/components/common/widget/TextTruncated");
const i18_1 = require("src/configeek_assets/src/i18");
const Analytics_1 = require("src/configeek_assets/src/system/analytics/Analytics");
const modalButtonsPropsInitialValue = {
    ok: { disabled: false, danger: true },
    cancel: {}
};
const RemoveProjectConfigKeyModalComponent = (props) => {
    var _a;
    const projectConfigStoreDataContext = (0, ProjectConfigStoreDataProvider_1.useProjectConfigStoreDataContext)();
    const [deleteError, setDeleteError] = (0, react_1.useState)(undefined);
    const [modalButtonProps, setModalButtonProps] = (0, react_1.useReducer)((state, newState) => ({ ...state, ...newState }), modalButtonsPropsInitialValue);
    const title = "Remove Parameter";
    const okText = "Remove";
    const errorText = i18_1.i18.project.configuration.error.modal.remove;
    const removeCancellable = (0, react_1.useMemo)(() => (0, promiseUtils_1.makeCancelableAsync)(async (key) => {
        const removeConfigKeyResult = await projectConfigStoreDataContext.removeConfigKey(key);
        if (removeConfigKeyResult.status === "success") {
            Analytics_1.Analytics.trackRemoveParameter();
            projectConfigStoreDataContext.fetchConfig();
        }
        return removeConfigKeyResult;
    }), [projectConfigStoreDataContext.removeConfigKey, projectConfigStoreDataContext.fetchConfig]);
    const asyncRemove = async () => {
        try {
            //disabled buttons
            setModalButtonProps({
                ok: { disabled: true, loading: true, },
                cancel: { disabled: true, }
            });
            if (props.configKeyValuePair == undefined) {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error("No configKey");
            }
            const result = await removeCancellable.run(props.configKeyValuePair.key);
            switch (result.state) {
                case "success": {
                    const removeConfigKeyResult = result.result;
                    if (!!process.env.IS_TEST_SERVER) {
                        console.log("asyncRemove removeCancellable result", removeConfigKeyResult);
                    }
                    switch (removeConfigKeyResult.status) {
                        case "success": {
                            props.onDestroy();
                            break;
                        }
                        case "error": {
                            // const reason = removeConfigKeyResult.reason;
                            //enabled buttons
                            setModalButtonProps({
                                ok: { disabled: false, loading: false, },
                                cancel: { disabled: false, }
                            });
                            setDeleteError(errorText);
                            break;
                        }
                        case "unknownError": {
                            // noinspection ExceptionCaughtLocallyJS
                            throw removeConfigKeyResult.error;
                        }
                    }
                    break;
                }
                case "cancelled": {
                    //skip
                    break;
                }
                case "error": {
                    // noinspection ExceptionCaughtLocallyJS
                    throw result.error;
                }
            }
        }
        catch (e) {
            console.error("RemoveProjectConfigKeyModalComponent: removeConfigKey caught error", e);
            //enabled buttons
            setModalButtonProps({
                ok: { disabled: false, loading: false, },
                cancel: { disabled: false, }
            });
            //update errors
            setDeleteError(errorText);
        }
    };
    const onCancel = () => {
        props.onDestroy();
    };
    const onOk = async () => {
        await asyncRemove();
    };
    return React.createElement(antd_1.Modal, { width: 550, closable: false, maskClosable: false, destroyOnClose: true, visible: props.visible, title: title, onCancel: onCancel, okText: okText, onOk: onOk, okButtonProps: modalButtonProps.ok, cancelButtonProps: modalButtonProps.cancel, className: "ug-modal" },
        deleteError && React.createElement(antd_1.Alert, { message: deleteError, type: "error" }),
        "Are you sure you want to remove \"",
        React.createElement(TextTruncated_1.TextTruncated, { text: ((_a = props.configKeyValuePair) === null || _a === void 0 ? void 0 : _a.key) || "", length: 40 }),
        "\" from \"",
        React.createElement(TextTruncated_1.TextTruncated, { text: props.projectName, length: 40 }),
        "\"?");
};
exports.RemoveProjectConfigKeyModalComponent = RemoveProjectConfigKeyModalComponent;
