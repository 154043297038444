"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDerivationOriginFromIOToAPP = void 0;
const isRawHost = (host) => host.match(/\.raw\.icp0\.io$/) != null || host.match(/\.raw\.ic0\.app$/) != null;
const isIOHost = (host) => host.match(/\.icp0\.io$/) != null;
const getDerivationOriginFromIOToAPP = (host, canisterId) => {
    if (isIOHost(host)) {
        let url = `https://${canisterId}.`;
        if (isRawHost(host)) {
            url += 'raw.';
        }
        url += 'ic0.app';
        return url;
    }
};
exports.getDerivationOriginFromIOToAPP = getDerivationOriginFromIOToAPP;
