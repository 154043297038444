"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTruncatedString = exports.truncateMiddle = exports.hasOwnProperty = void 0;
/**
 * We want to check if prop is a property key of obj
 * @param obj - object
 * @param prop - property
 * @link https://fettblog.eu/typescript-hasownproperty/
 */
function hasOwnProperty(obj, prop) {
    return obj.hasOwnProperty(prop);
}
exports.hasOwnProperty = hasOwnProperty;
const truncateMiddle = function (fullStr = "", strLen, separator) {
    if (fullStr.length <= strLen)
        return fullStr;
    separator = separator || '...';
    const sepLen = separator.length, charsToShow = strLen - sepLen, frontChars = Math.ceil(charsToShow / 2), backChars = Math.floor(charsToShow / 2);
    return fullStr.substr(0, frontChars) +
        separator +
        fullStr.substr(fullStr.length - backChars);
};
exports.truncateMiddle = truncateMiddle;
function getTruncatedString(str, length) {
    if (str.length > length) {
        return [str.substr(0, length - 1), true];
    }
    return [str, false];
}
exports.getTruncatedString = getTruncatedString;
