"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToolbarComponent = void 0;
const React = __importStar(require("react"));
const antd_1 = require("antd");
const ErrorBoundaryComponent_1 = require("src/configeek_assets/src/components/common/errorBoundaryComponent/ErrorBoundaryComponent");
const ToolbarLogoComponent_1 = require("src/configeek_assets/src/components/loggedIn/toolbar/ToolbarLogoComponent");
const ToolbarUserMenu_1 = require("src/configeek_assets/src/components/loggedIn/toolbar/ToolbarUserMenu");
const ToolbarProjectSwitcherComponent_1 = require("src/configeek_assets/src/components/loggedIn/toolbar/ToolbarProjectSwitcherComponent");
const ToolbarGoToComponent_1 = require("src/configeek_assets/src/components/loggedIn/toolbar/ToolbarGoToComponent");
const ToolbarComponent = ({}) => {
    return React.createElement(antd_1.Row, { wrap: false, align: "middle", className: "toolbarContent" },
        React.createElement(antd_1.Col, { className: "toolbarLogoCol" },
            React.createElement(ErrorBoundaryComponent_1.ErrorBoundaryComponent, { childComponentName: "ToolbarLogoComponent" },
                React.createElement(ToolbarLogoComponent_1.ToolbarLogoComponent, null))),
        React.createElement(antd_1.Col, { flex: "auto", className: "toolbarContentCol" },
            React.createElement(antd_1.Row, { align: "middle" },
                React.createElement(antd_1.Col, { className: "toolbarContentGoToComponent" },
                    React.createElement(ToolbarGoToComponent_1.ToolbarGoToComponent, null)))),
        React.createElement(antd_1.Col, null,
            React.createElement(ErrorBoundaryComponent_1.ErrorBoundaryComponent, { childComponentName: "ToolbarProjectSwitcherComponent" },
                React.createElement(ToolbarProjectSwitcherComponent_1.ToolbarProjectSwitcherComponent, null))),
        React.createElement(antd_1.Col, null,
            React.createElement(ErrorBoundaryComponent_1.ErrorBoundaryComponent, { childComponentName: "ToolbarUserMenu" },
                React.createElement(ToolbarUserMenu_1.ToolbarUserMenu, null))));
};
exports.ToolbarComponent = ToolbarComponent;
