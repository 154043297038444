"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigeekIconSVG = void 0;
const React = __importStar(require("react"));
const ConfigeekIconSVG = () => React.createElement("svg", { width: "1em", height: "1em", version: "1.1", viewBox: "0 0 500 500", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { fill: "#2b3541" },
        React.createElement("polygon", { points: "377.2,455.9 112.3,455.9 112.3,342.7 81.3,342.7 81.3,487 406.5,487 406.5,342.7 377.2,342.7 \t" }),
        React.createElement("polygon", { points: "123.4,113.6 165.2,123 190.5,98.4 185.3,51.9 208.9,41.3 234.1,77.8 269.1,77.8 269.2,77.8 295.8,42.2 317.8,53.4\n\t\t306.2,97.9 328.9,123.4 329,123.6 368.7,119.8 380.8,158.2 406.5,158.2 390.2,106.8 385.8,93.2 338.5,97.6 331.5,89.7 342.1,39.4\n\t\t286.2,12.1 256.7,52.2 245.9,52.2 217,10 159.1,36.2 165,87.9 157.5,95.3 107.9,85.6 81.2,158.2 107,158.2 \t" }),
        React.createElement("path", { d: "M152.3,314.8c29,0,72.6-30.7,91.7-45.1c8.2,6.2,35.1,23.7,35.1,23.7v63.9h-84v29.2h113.1v-29.9v-48.1\n\tc10.2,3.7,19.4,6.3,27.3,6.3c39.2,0,71.1-28.2,71.1-62.8s-31.9-62.8-71.1-62.8c-29,0-72.6,30.7-91.7,45.1\n\tc-19.1-14.4-62.8-45.1-91.6-45.1c-39.2,0-71.1,28.2-71.1,62.8C81.2,286.6,113.1,314.8,152.3,314.8z M266.4,252.4\n\tc22.7-16.6,53.5-35.4,69.5-35.4c24.1,0,43.6,15.9,43.6,35.4s-19.6,35.4-43.6,35.4C320.1,287.8,289.2,269,266.4,252.4L266.4,252.4\n\tL266.4,252.4z M152.7,217c15.8,0,46.7,18.8,69.5,35.4l-0.1,0.1h0.1c-22.7,16.6-53.5,35.4-69.5,35.4c-24.1,0-43.6-15.9-43.6-35.4\n\tS128.7,217,152.7,217z" })));
exports.ConfigeekIconSVG = ConfigeekIconSVG;
