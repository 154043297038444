"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegalContent = void 0;
const React = __importStar(require("react"));
const constants_1 = require("src/configeek_assets/src/constants");
const LegalContent = () => {
    return React.createElement(React.Fragment, null,
        React.createElement("p", null,
            "These terms of service (",
            React.createElement("b", null, "\u201CTerms of Service\u201D"),
            ") govern the access to and use of the Service (as defined in Section Service) provided by Rubyspark Labs PTE. LTD, a company established under the laws of Singapore, with registration number 201420666D, registered address 1 George Street #10-01, 049145 Singapore (",
            React.createElement("b", null, "\u201CRubyspark Labs\u201D"),
            ") and which is accessible through ",
            React.createElement("a", { href: constants_1.URL_IC_ROOT_DOMAIN, target: "_blank" },
                React.createElement("u", null, constants_1.URL_IC_ROOT_DOMAIN)),
            " (",
            React.createElement("b", null, "\u201CWebsite\u201D"),
            ")."),
        React.createElement("p", null, "User is entering into a legally binding agreement with Rubyspark Labs on behalf of a legal entity, and is agreeing to these Terms of Service for that legal entity and representing and warranting to Rubyspark Labs that it has the authority to bind that legal entity to these Terms of Service. If the User does not have such authority it must not accept these Terms of Service and may not use the Service."),
        React.createElement("p", null, "1. Service"),
        React.createElement("p", null, "Rubyspark Labs grants access through the Website to confiGeek, the remote configuration service."),
        React.createElement("p", null, "2. Use of Beta Service"),
        React.createElement("p", null, "confiGeek is currently in beta."),
        React.createElement("p", null, "The Beta Service is provided on \u201Cas is\u201D basis, and no warranty or representation is given that the features contained in the Beta Service will meet the User\u2019s requirements, or that the operation of the Beta Service will be uninterrupted or error-free, or that defects in the Beta Service will be corrected. Rubyspark Labs may in its sole discretion modify the Beta Service, at any time, without prior notice. The Beta Service may contain bugs, errors and other issues. Rubyspark Labs is not obligated to provide any maintenance, technical or other support for the Beta Service. The Beta Service is experimental, and provision of the Beta Service shall not create any obligation for Rubyspark Labs to continue to develop, productise, support, repair, offer for sale or in any other way continue to provide or develop the Beta Service."),
        React.createElement("p", null, "Rubyspark Labs will use it's best efforts to make sure that User's data is safe and always available."),
        React.createElement("p", null, "The User assumes all risks and costs associated with the User\u2019s use of the Beta Service, including, without limitation, any back-up expenses, costs incurred for the use of the User\u2019s devices, any damage to any of the User\u2019s devices, equipment, software, data (including personal data), information (including business secrets and confidential information), end user content or intellectual property. Furthermore, no warranty or representation is given regarding the use or the results deriving from the use of the Beta Service."),
        React.createElement("p", null, "3. Support"),
        "email: ",
        React.createElement("a", { href: constants_1.URL_MAIL_SUPPORT, target: "_blank" }, constants_1.MAIL_SUPPORT),
        React.createElement("br", null),
        "discord: ",
        React.createElement("a", { href: constants_1.URL_DISCORD, target: "_blank" }, constants_1.URL_DISCORD));
};
exports.LegalContent = LegalContent;
