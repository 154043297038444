"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeFilter = void 0;
const antd_1 = require("antd");
const React = __importStar(require("react"));
const react_1 = require("react");
const AuditLogPageModelProvider_1 = require("src/configeek_assets/src/components/loggedIn/content/project/data/audit/model/AuditLogPageModelProvider");
const DateTimeUtils_1 = require("src/configeek_assets/src/system/mics/DateTimeUtils");
const TimeFilter = () => {
    const pageModelContext = (0, AuditLogPageModelProvider_1.useAuditLogPageModelContext)();
    const jsonModel = pageModelContext.pageModelStateFns.getFormJSONModel();
    const onChange = (0, react_1.useCallback)((value, dateString) => {
        pageModelContext.pageModelStateFns.onTimeFilterChanged(value === null || value === void 0 ? void 0 : value.valueOf());
    }, [pageModelContext.pageModelStateFns.onTimeFilterChanged]);
    // const disabledDate: RangePickerProps['disabledDate'] = useCallback((date: DatePickerProps['value']): boolean => {
    //     if (date) {
    //         return date.valueOf() > new Date().getTime()
    //     } else {
    //         return true
    //     }
    // }, [])
    //
    // const disabledDateTime = () => ({
    //     disabledHours: () => range(0, 24).splice(4, 20),
    //     disabledMinutes: () => range(30, 60),
    //     disabledSeconds: () => [55, 56],
    // });
    const format = (0, react_1.useCallback)((value) => {
        return DateTimeUtils_1.DateTimeUtils.formatDate(value.valueOf(), "dayTimeSeconds");
    }, []);
    const timeFromValue = jsonModel.getTimeFilter().getValue();
    const selectValue = timeFromValue == undefined ? undefined : DateTimeUtils_1.DateTimeUtils.getMomentFromMillis(timeFromValue);
    return React.createElement(React.Fragment, null,
        React.createElement(antd_1.Form.Item, { label: "Time From" },
            React.createElement(antd_1.DatePicker, { showTime: true, value: selectValue, format: format, placeholder: "Time", onChange: onChange, style: { width: "100%" }, allowClear: true })));
};
exports.TimeFilter = TimeFilter;
