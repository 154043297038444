"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VPgeekIconSVG = void 0;
const React = __importStar(require("react"));
const VPgeekIconSVG = () => React.createElement("svg", { width: "1em", height: "1em", version: "1.1", viewBox: "0 0 400 400", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fill: "currentColor", d: "m91.028 107.52h35.974l-0.15-80.643h24.583v80.643h35.974v-105.23h24.583v105.23h35.825v-80.643h24.582v80.643h35.975v-40.322h24.582v40.322 24.582h-266.51v-24.582-40.322h24.583zm-24.133 86.339h104.33v24.582h-104.33zm92.334 98.18v24.583h94.284v-24.583-73.598h79.743v-24.582h-104.33v24.582 73.598zm174.33 105.68v-117.67h-24.733v92.934h-217.5v-92.934h-24.732v117.67z" }));
exports.VPgeekIconSVG = VPgeekIconSVG;
