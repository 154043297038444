"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParticipantUtils = exports.ParticipantRoleUtils = void 0;
const typescriptAddons_1 = require("src/configeek_assets/src/system/typescriptAddons");
exports.ParticipantRoleUtils = {
    isOwnerRole: (role) => (0, typescriptAddons_1.hasOwnProperty)(role, "owner"),
    isAdminRole: (role) => (0, typescriptAddons_1.hasOwnProperty)(role, "admin"),
    isViewerRole: (role) => (0, typescriptAddons_1.hasOwnProperty)(role, "viewer"),
    getRoleName: (role) => {
        if (exports.ParticipantRoleUtils.isOwnerRole(role)) {
            return "Owner";
        }
        else if (exports.ParticipantRoleUtils.isViewerRole(role)) {
            return "Viewer";
        }
        else if (exports.ParticipantRoleUtils.isAdminRole(role)) {
            return "Admin";
        }
        return "Unknown";
    },
    isOwnerOrAdminRole: (role) => exports.ParticipantRoleUtils.isOwnerRole(role) || exports.ParticipantRoleUtils.isAdminRole(role),
};
exports.ParticipantUtils = {
    hasProjectMemberAccess: (participant) => exports.ParticipantRoleUtils.isOwnerOrAdminRole(participant.role),
    hasProjectEditAccess: (participant) => exports.ParticipantRoleUtils.isOwnerOrAdminRole(participant.role),
    hasProjectParameterEditAccess: (participant) => exports.ParticipantRoleUtils.isOwnerOrAdminRole(participant.role),
    hasProjectAuditLogAccess: (participant) => exports.ParticipantRoleUtils.isOwnerOrAdminRole(participant.role),
};
