"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateThemeId = exports.useApplyStylesHook = void 0;
const react_1 = require("react");
let counter = 0;
function useApplyStylesHook(styles) {
    (0, react_1.useEffect)(() => {
        counter--;
        return () => styles.unuse();
    });
    counter++;
    styles.use();
}
exports.useApplyStylesHook = useApplyStylesHook;
const validateThemeId = (value) => {
    switch (value) {
        case "dark":
            return "dark";
        default:
            return "light";
    }
};
exports.validateThemeId = validateThemeId;
