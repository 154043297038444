"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Profile = void 0;
const React = __importStar(require("react"));
const PageContent_1 = require("src/configeek_assets/src/components/common/widget/PageContent");
const antd_1 = require("antd");
const geekfactory_ic_js_auth_1 = require("geekfactory-ic-js-auth");
const Profile = () => {
    var _a;
    const authProviderContext = (0, geekfactory_ic_js_auth_1.useAuthProviderContext)();
    const principalString = (_a = authProviderContext.getCurrentPrincipal()) === null || _a === void 0 ? void 0 : _a.toText();
    const source = authProviderContext.source;
    const accountName = source == "II" ? "Internet Identity" :
        source == "NFID" ? "NFID" :
            source == "Plug" ? "Plug" :
                source == "InfinityWallet" ? "InfinityWallet" :
                    source == "Stoic" ? "Stoic" : "Unknown";
    return React.createElement(React.Fragment, null,
        React.createElement(antd_1.PageHeader, { title: "Profile" }),
        React.createElement(PageContent_1.PageContent, null,
            React.createElement(PageContent_1.PageContent.CardSpacer, null),
            React.createElement(PageContent_1.PageContent.Card, null,
                React.createElement(antd_1.Row, null,
                    React.createElement(antd_1.Col, { span: 24, lg: 12 },
                        React.createElement(antd_1.Space, { direction: "vertical" },
                            React.createElement("b", null, accountName),
                            React.createElement("span", null,
                                "Principal: ",
                                React.createElement(antd_1.Typography.Text, { code: true, copyable: true, className: "apiKey" }, principalString))))))));
};
exports.Profile = Profile;
