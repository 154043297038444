"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormComponent = void 0;
const React = __importStar(require("react"));
const react_1 = require("react");
const AuditLogPageDataProvider_1 = require("src/configeek_assets/src/components/loggedIn/content/project/data/audit/model/AuditLogPageDataProvider");
const AuditLogPageModelProvider_1 = require("src/configeek_assets/src/components/loggedIn/content/project/data/audit/model/AuditLogPageModelProvider");
const AuditLogJSONModel_1 = require("src/configeek_assets/src/components/loggedIn/content/project/data/audit/model/AuditLogJSONModel");
const antd_1 = require("antd");
const ShowButton_1 = require("src/configeek_assets/src/components/common/widget/ShowButton");
const TimeFilter_1 = require("src/configeek_assets/src/components/loggedIn/content/project/data/audit/form/TimeFilter");
const KeyFilter_1 = require("src/configeek_assets/src/components/loggedIn/content/project/data/audit/form/KeyFilter");
const ValueFilter_1 = require("src/configeek_assets/src/components/loggedIn/content/project/data/audit/form/ValueFilter");
const ShowNewLinesSettingsComponent_1 = require("src/configeek_assets/src/components/common/widget/ShowNewLinesSettingsComponent");
const OrderFilter_1 = require("src/configeek_assets/src/components/loggedIn/content/project/data/audit/form/OrderFilter");
const FormComponent = () => {
    const pageDataContext = (0, AuditLogPageDataProvider_1.useAuditLogPageDataContext)();
    const pageModelContext = (0, AuditLogPageModelProvider_1.useAuditLogPageModelContext)();
    const jsonModel = pageModelContext.pageModelStateFns.getFormJSONModel();
    const showButtonDisabled = AuditLogJSONModel_1.AuditLogJSONModel.isModelEmpty(jsonModel.serialize());
    const onChangeNewLinesVisible = (0, react_1.useCallback)((value) => {
        const viewJSONModel = pageDataContext.stateFns.getURLJSONModel().getView();
        viewJSONModel.getNewLinesVisible().setValue(value);
        pageDataContext.stateFns.onViewChanged(viewJSONModel.serialize());
    }, [pageDataContext.stateFns]);
    const urlJSONModel = pageDataContext.stateFns.getURLJSONModel();
    const newLinesVisible = urlJSONModel.getView().getNewLinesVisible().getValue() || false;
    const newLinesVisibleSettings = React.createElement(ShowNewLinesSettingsComponent_1.ShowNewLinesSettingsComponent, { value: newLinesVisible, onChange: onChangeNewLinesVisible, bottomPadding: false, disabled: pageDataContext.state.status.inProgress });
    return React.createElement(React.Fragment, null,
        React.createElement(antd_1.Form, { layout: "vertical" },
            React.createElement(antd_1.Row, { gutter: [16, 12] },
                React.createElement(antd_1.Col, { span: 9, xxl: 3, xl: 3, lg: 6, md: 6 },
                    React.createElement(KeyFilter_1.KeyFilter, null)),
                React.createElement(antd_1.Col, { span: 9, xxl: 3, xl: 3, lg: 6, md: 6 },
                    React.createElement(ValueFilter_1.ValueFilter, null)),
                React.createElement(antd_1.Col, { span: 9, xxl: 4, xl: 4, lg: 7, md: 7 },
                    React.createElement(TimeFilter_1.TimeFilter, null)),
                React.createElement(antd_1.Col, { span: 9, xxl: 3, xl: 3, lg: 6, md: 6 },
                    React.createElement(OrderFilter_1.OrderFilter, null)),
                React.createElement(antd_1.Col, null,
                    React.createElement(antd_1.Form.Item, { label: React.createElement("span", { style: { whiteSpace: "pre" } }, " ") },
                        React.createElement(ShowButton_1.ShowButton, { inProgress: pageDataContext.state.status.inProgress, onClick: pageModelContext.pageModelStateFns.show, disabled: showButtonDisabled, size: "middle" }))),
                React.createElement(antd_1.Col, { flex: "auto" },
                    React.createElement(antd_1.Form.Item, { label: React.createElement("span", { style: { whiteSpace: "pre" } }, " ") }, newLinesVisibleSettings)))));
};
exports.FormComponent = FormComponent;
